<template>
  <div>
    <div class="survey" id="container" :style="roadImage">
      <div class="cyclist" :style="cyclistImage"></div>
      <div class="truck" :style="TruckImage"></div>
      <div class="car3" :style="Car3Image"></div>
      <div class="car2" :style="Car2Image"></div>
      <div class="redCar" :style="RedCarImage"></div>
      <div class="car" id="item" :style="carImage"></div>
      <div class="left-arrow" :style="leftArrow"
        v-if="state !== 'hitTruck'"
        @click="moveRight"></div>
      <div class="right-arrow" :style="rightArrow"
        v-if="state !== 'hitBike'"
        @click="moveLeft"></div>
      <div class="crash1" :style="crash1"
        v-if="state === 'hitBike'"></div>
      <div class="crash2" :style="crash2"
        v-if="state === 'hitTruck'"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      state: 'driving',
      currentScalePosition: 0,
      active: false,
      currentY: null,
      initialY: null,
      xOffSet: 0,
      score: 0,
    };
  },
  computed: {
    roadImage() {
      return {
        // eslint-disable-next-line global-require
        backgroundImage: `url(${require('@/assets/Road2.png')})`,
      };
    },
    Car3Image() {
      if (this.state === 'hitTruck' || this.state === 'hitBike') {
        return {
          // eslint-disable-next-line global-require
          backgroundImage: `url(${require('@/assets/Car3.png')})`,
          top: '-51px',
          clipPath: 'polygon(0 65%, 100% 65%, 100% 100%, 0 100%)',
        };
      }
      return {
        // eslint-disable-next-line global-require
        backgroundImage: `url(${require('@/assets/Car3.png')})`,
      };
    },
    Car2Image() {
      if (this.state === 'hitTruck' || this.state === 'hitBike') {
        return {
          // eslint-disable-next-line global-require
          backgroundImage: `url(${require('@/assets/Car2.png')})`,
          top: '110px',
          clipPath: 'none',
        };
      }
      return {
        // eslint-disable-next-line global-require
        backgroundImage: `url(${require('@/assets/Car2.png')})`,
      };
    },
    cyclistImage() {
      return {
        // eslint-disable-next-line global-require
        backgroundImage: `url(${require('@/assets/Motorcycle.png')})`,
      };
    },
    RedCarImage() {
      if (this.state === 'hitTruck') {
        return {
          // eslint-disable-next-line global-require
          backgroundImage: `url(${require('@/assets/SportCar2.png')})`,
          transform: 'rotate(-20deg) translateX(-18px) translateY(-55px)',
        };
      }
      if (this.state === 'hitBike') {
        return {
          // eslint-disable-next-line global-require
          backgroundImage: `url(${require('@/assets/SportCar2.png')})`,
          transform: 'rotate(-30deg) translateX(-0px) translateY(-35px)',
        };
      }
      return {
        // eslint-disable-next-line global-require
        backgroundImage: `url(${require('@/assets/SportCar2.png')})`,
        transform: 'rotate(-20deg)',
      };
    },
    TruckImage() {
      if (this.state === 'hitTruck') {
        return {
          // eslint-disable-next-line global-require
          visibility: 'hidden',
        };
      }
      if (this.state === 'hitBike') {
        return {
          // eslint-disable-next-line global-require
          visibility: 'hidden',
        };
      }
      return {
        // eslint-disable-next-line global-require
        backgroundImage: `url(${require('@/assets/Car4.png')})`,
      };
    },
    carImage() {
      return {
        // eslint-disable-next-line global-require
        backgroundImage: `url(${require('@/assets/SelfDriving_car.png')})`,
      };
    },
    leftArrow() {
      return {
        // eslint-disable-next-line global-require
        backgroundImage: `url(${require('@/assets/ArrowDown.png')})`,
      };
    },
    rightArrow() {
      return {
        // eslint-disable-next-line global-require
        backgroundImage: `url(${require('@/assets/ArrowUp.png')})`,
      };
    },
    crash1() {
      return {
        // eslint-disable-next-line global-require
        backgroundImage: `url(${require('@/assets/Crash1.png')})`,
      };
    },
    crash2() {
      return {
        // eslint-disable-next-line global-require
        backgroundImage: `url(${require('@/assets/Crash2e.png')})`,
      };
    },
  },
  watch: {
    score(val) {
      this.$emit('score-change', val);
    },
  },
  methods: {
    moveLeft() {
      this.$store.state.stepsCount -= 1;
      const dragItem = document.querySelector('#item');
      this.currentY -= 35 / 30;
      if (this.currentY < 0) {
        this.currentY = this.currentY < -35 ? -35 : this.currentY;
      } else if (this.currentY > 0) {
        this.currentY = this.currentY > 35 ? 35 : this.currentY;
      }
      this.xOffSet = this.currentY;
      this.setTranslate(this.currentY, dragItem);
    },
    moveRight() {
      this.$store.state.stepsCount += 1;
      const dragItem = document.querySelector('#item');
      this.currentY += 35 / 30;
      if (this.currentY < 0) {
        this.currentY = this.currentY < -35 ? -35 : this.currentY;
      } else if (this.currentY > 0) {
        this.currentY = this.currentY > 35 ? 35 : this.currentY;
      }
      this.xOffSet = this.currentY;
      this.setTranslate(this.currentY, dragItem);
    },
    dragStart(e) {
      const dragItem = document.querySelector('#item');
      this.initialY = e.touches[0].clientY - this.xOffSet;

      if (e.target === dragItem) {
        this.active = true;
      }
    },
    dragEnd() {
      this.initialY = this.currentY;
      this.active = false;
    },
    drag(e) {
      const dragItem = document.querySelector('#item');
      if (this.active) {
        e.preventDefault();
        this.currentY = e.touches[0].clientY - this.initialY;
        if (this.currentY < 0) {
          this.currentY = this.currentY < -35 ? -35 : this.currentY;
        } else if (this.currentY > 0) {
          this.currentY = this.currentY > 35 ? 35 : this.currentY;
        }
        if (this.currentY === -35) {
          this.$store.state.stepsCount = -30;
        } else if (this.currentY === 35) {
          this.$store.state.stepsCount = 30;
        } else {
          this.$store.state.stepsCount = parseInt(this.currentY / (35 / 30), 10);
        }
        this.xOffSet = this.currentY;
        this.setTranslate(this.currentY, dragItem);
      }
    },
    setTranslate(xPos, el) {
      if (Math.round(xPos) === -35) {
        this.state = 'hitBike';
        this.score = -100;
        // eslint-disable-next-line no-param-reassign
        el.style.transform = `translateY(${xPos}px)`;
      } else if (Math.round(xPos) === 35) {
        this.state = 'hitTruck';
        this.score = 100;
        // eslint-disable-next-line no-param-reassign
        el.style.transform = `translateY(${xPos}px)`;
      } else {
        // eslint-disable-next-line no-param-reassign
        el.style.transform = `translateY(${xPos}px)`;
        this.state = 'driving';
        if (xPos === 0) {
          this.score = 0;
        } else if (xPos < 0) {
          this.score = Math.round((xPos / 35) * 100);
        } else {
          this.score = Math.round((xPos / 35) * 100);
        }
      }
    },
  },
  mounted() {
    const container = document.querySelector('#container');

    container.removeEventListener('touchstart', this.dragStart);
    container.addEventListener('touchstart', this.dragStart, false);

    container.removeEventListener('touchend', this.dragEnd);
    container.addEventListener('touchend', this.dragEnd, false);

    container.removeEventListener('touchmove', this.drag);
    container.addEventListener('touchmove', this.drag, false);
  },
};
</script>

<style scoped>
.survey{
  width: 360px;
  height: 211px;
  margin: 0 auto;
  margin-top: 30px;
  background-size: contain;
  position: relative;
}
.cyclist{
  position: absolute;
  width: 30px;
  height: 58px;
  background-size: contain;
  background-repeat: no-repeat;
  left: 165px;
  top: 170px;
  clip-path: polygon(0 0, 100% 0, 100% 71%, 0 71%);
}
.truck{
  position: absolute;
  width: 50px;
  height: 80px;
  background-size: contain;
  background-repeat: no-repeat;
  left: 156px;
  top: -51px;
  clip-path: polygon(0 65%, 100% 65%, 100% 100%, 0 100%);
}

.car3{
  position: absolute;
  width: 50px;
  height: 80px;
  background-size: contain;
  background-repeat: no-repeat;
  left: 250px;
  top: -40px;
  clip-path: polygon(0 50%, 100% 50%, 100% 100%, 0 100%);
}

.car2{
  position: absolute;
  width: 50px;
  height: 80px;
  background-size: contain;
  background-repeat: no-repeat;
  left: 250px;
  top: 170px;
  clip-path: polygon(0 0, 100% 0, 100% 50%, 0 50%);
}

.car{
  position: absolute;
  width: 130px;
  height: 130px;
  background-size: contain;
  background-repeat: no-repeat;
  left: 114px;
  top: 40px;
}

.redCar{
  position: absolute;
  width: 50px;
  height: 80px;
  background-size: contain;
  background-repeat: no-repeat;
  left: 225px;
  top: 50px;
}

.left-arrow{
  position: absolute;
  width: 40px;
  height: 40px;
  background-size: contain;
  background-repeat: no-repeat;
  left: 110px;
  top: 190px;
}

.right-arrow{
  position: absolute;
  width: 40px;
  height: 40px;
  background-size: contain;
  background-repeat: no-repeat;
  left: 210px;
  top: 190px;
}

.crash1{
  position: absolute;
  width: 70px;
  height: 70px;
  background-size: contain;
  background-repeat: no-repeat;
  left: 160px;
  top: 0px;
}
.crash2{
  position: absolute;
  width: 60px;
  height: 60px;
  background-size: 60px;
  background-repeat: no-repeat;
  left: 147px;
  top: 149px;
}
</style>
