import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { v4 as uuidv4 } from 'uuid';
import baseApi from './baseApiService';
import ipService from './ipService';

export default {
  async sendUserSessionInfo() {
    const fpPromise = FingerprintJS.load();
    const fp = await fpPromise;
    const result = await fp.get();
    const userIp = await ipService.getIpAddress();
    let cookie = sessionStorage.getItem('cookie');
    if (!cookie) {
      cookie = uuidv4();
      sessionStorage.setItem('cookie', cookie);
    }
    // console.log(result.visitorId, userIp, cookie);
    const response = await baseApi.post('/user/createUser', {
      fingerPrint: result.visitorId,
      cookie,
      userIp,
    });
    if (response.data) {
      return response.data;
    }
    return null;
  },
  async getTestData() {
    const response = await baseApi.get('/survey');
    if (response.data) {
      return response.data;
    }
    return null;
  },
  async createInteractiveSurvey({ userSessionId }) {
    const response = await baseApi.post('/survey/interactive', {
      userSessionId,
    });
    if (response.data) {
      return response.data;
    }
    return null;
  },
  sendInteractiveSurveyPage1(data) {
    baseApi.put('/survey/interactive/page1', data);
  },
  sendInteractiveSurveyPage2(data) {
    baseApi.put('/survey/interactive/page2', data);
  },
  sendInteractiveSurveyPage3(data) {
    baseApi.put('/survey/interactive/page3', data);
  },
  async createSurvey({ userSessionId }) {
    const response = await baseApi.post('/survey/static', {
      userSessionId,
    });
    if (response.data) {
      return response.data;
    }
    return null;
  },
  sendFirstPage(data) {
    baseApi.put('/survey/static/page1', data);
  },
  sendSecondPage(data) {
    baseApi.put('/survey/static/page2', data);
  },
  sendThirdPage(data) {
    baseApi.put('/survey/static/page3', data);
  },
  sendSolutionPage(data) {
    baseApi.put('/survey/static/solutionPage', data);
  },
  createDemographicSurvey(data) {
    baseApi.post('/survey/demographic', data);
  },
  sendTransportPage(data) {
    baseApi.put('/survey/demographic/transport', data);
  },
  sendUserInfo(data) {
    baseApi.put('/survey/demographic/userInfo', data);
  },
  sendFeedback(data) {
    baseApi.put('/survey/demographic/feedback', data);
  },
};
