<template>
  <div class="container feedback-container">
    <p v-if="feedbackTitle !== '-'">{{feedbackTitle}}</p>
    <div class="form-group">
      <textarea class="form-control" v-model="feedback" rows="6"></textarea>
    </div>
    <div class="continue-container">
      <continue-button :disabled="!canContinue" :text="finishText"
        @click="submit" >Continue</continue-button>
    </div>
  </div>
</template>

<script>
import ContinueButton from '../../components/continue-button/continue-button.vue';

export default {
  components: {
    ContinueButton,
  },
  data() {
    return {
      feedback: '',
    };
  },
  mounted() {
    this.scrollToTop();
  },
  computed: {
    translations() {
      return this.$store.state.translations;
    },
    feedbackTitle() {
      const def = 'If you had any other feedback or thoughts, you can enter them here:';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'feedbackTitle');
      return t ? t.value : def;
    },
    finishText() {
      const def = 'Finish';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'finishText');
      return t ? t.value : def;
    },
    canContinue() {
      return true;
    },
  },
  methods: {
    scrollToTop() {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    },
    submit() {
      this.$store.dispatch('submitFeedback', {
        userSessionId: this.$store.state.userSessionInfo.userSessionId,
        feedBack: this.feedback,
      });
      sessionStorage.setItem('finalSectionCount', 1);
      this.$router.push('done');
    },
  },
};
</script>

<style scoped>
.feedback-container{
  margin-top: 60px;
}
.userinfo-container p{
  font-family: 'Lato', serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  color: #080D25;
  margin-bottom: 30px;
}
.continue-container{
  display:flex;
  justify-content: center;
  align-content: center;
  margin-top: 40px;
}
</style>
