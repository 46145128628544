var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"charts row"},[_c('div',{staticClass:"chart",class:{'col-6': _vm.isWithSaved}},[_c('div',{staticClass:"chart-header"},[(_vm.chartTitle !== '-')?_c('div',{staticClass:"chart-title"},[_vm._v(_vm._s(_vm.chartTitle))]):_vm._e(),_c('div',{staticClass:"chart-total"},[_vm._v(_vm._s(_vm._f("formatNumber")(_vm.totalNumber)))])]),_c('div',{ref:"chartContainer",staticClass:"chart-container",class:{'no-visibility': !_vm.chartLoaded}},[_c('div',{staticClass:"bar-contaienr",style:({width: _vm.twoWheelerBarPercentage+'%'})},[_c('div',{ref:"twoWheeler",staticClass:"two-wheeler-bar bar"})]),_c('div',{staticClass:"bar-contaienr",style:({width: _vm.cyclistsBarPercentage+'%'})},[_c('div',{ref:"cyclistsBar",staticClass:"cyclists-bar bar"})]),_c('div',{staticClass:"bar-contaienr",style:({width: _vm.pedestriansBarPercentage+'%'})},[_c('div',{ref:"pedestriansBar",staticClass:"pedestrians-bar bar"})]),_c('div',{staticClass:"bar-contaienr",style:({width: _vm.carPassengersBarPercentage+'%'})},[_c('div',{ref:"carPassengersBar",staticClass:"car-passengers-bar bar"})])]),_c('div',{staticClass:"info-container row"},[_c('div',{staticClass:"info info-two-wheelers col-md-3",class:{'col-sm-12': _vm.isWithSaved,
        'col-sm-6': !_vm.isWithSaved, 'col-6': !_vm.isWithSaved}},[(_vm.twoWheelersText !== '-')?_c('div',{staticClass:"info-title"},[_c('span',{staticClass:"info-label"},[_vm._v(_vm._s(_vm.twoWheelersText))])]):_vm._e(),_c('div',{staticClass:"info-content"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/surveyIcons/1.svg")}}),_c('span',{staticClass:"info-percentage"},[_vm._v(_vm._s(_vm.twoWheelerPercentage))]),_c('span',{staticClass:"info-number"},[_vm._v("("+_vm._s(_vm._f("formatNumber")(_vm.twoWheelers))+")")])])]),_c('div',{staticClass:"info info-two-wheelers col-md-3",class:{'col-sm-12': _vm.isWithSaved,
        'col-sm-6': !_vm.isWithSaved, 'col-6': !_vm.isWithSaved}},[_c('div',{staticClass:"info-title"},[(_vm.cyclistsText !== '-')?_c('span',{staticClass:"info-label"},[_vm._v(_vm._s(_vm.cyclistsText))]):_vm._e()]),_c('div',{staticClass:"info-content"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/surveyIcons/2.svg")}}),_c('span',{staticClass:"info-percentage"},[_vm._v(_vm._s(_vm.cyclistsPercentage))]),_c('span',{staticClass:"info-number"},[_vm._v("("+_vm._s(_vm._f("formatNumber")(_vm.cyclists))+")")])])]),_c('div',{staticClass:"info info-two-wheelers col-md-3",class:{'col-sm-12': _vm.isWithSaved,
        'col-sm-6': !_vm.isWithSaved, 'col-6': !_vm.isWithSaved}},[_c('div',{staticClass:"info-title"},[(_vm.pedestrianText !== '-')?_c('span',{staticClass:"info-label"},[_vm._v(_vm._s(_vm.pedestrianText))]):_vm._e()]),_c('div',{staticClass:"info-content"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/surveyIcons/3.svg")}}),_c('span',{staticClass:"info-percentage"},[_vm._v(_vm._s(_vm.pedestriansPercentage))]),_c('span',{staticClass:"info-number"},[_vm._v("("+_vm._s(_vm._f("formatNumber")(_vm.pedestrians))+")")])])]),_c('div',{staticClass:"info info-two-wheelers col-md-3",class:{'col-sm-12': _vm.isWithSaved,
        'col-sm-6': !_vm.isWithSaved, 'col-6': !_vm.isWithSaved}},[_c('div',{staticClass:"info-title"},[(_vm.carPassengersText !== '-')?_c('span',{staticClass:"info-label"},[_vm._v(_vm._s(_vm.carPassengersText))]):_vm._e()]),_c('div',{staticClass:"info-content"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/surveyIcons/4.svg")}}),_c('span',{staticClass:"info-percentage"},[_vm._v(_vm._s(_vm.carPassengersPercentage))]),_c('span',{staticClass:"info-number"},[_vm._v("("+_vm._s(_vm._f("formatNumber")(_vm.carPassengers))+")")])])])])]),(_vm.isWithSaved)?_c('div',{staticClass:"chart col-6 saved-chart"},[_c('div',{staticClass:"chart-header"},[_c('div',{staticClass:"chart-title"},[_vm._v(_vm._s(_vm.totalSavedPeople))]),_c('div',{staticClass:"chart-total"},[_vm._v(_vm._s(_vm._f("formatNumber")(_vm.totalNumber)))])]),_c('div',{ref:"chartContainer",staticClass:"chart-container",class:{'no-visibility': !_vm.chartLoaded}},[_c('div',{staticClass:"bar-contaienr",style:({width: _vm.twoWheelerBarPercentageSaved+'%'})},[_c('div',{ref:"twoWheeler",staticClass:"two-wheeler-bar bar"})]),_c('div',{staticClass:"bar-contaienr",style:({width: _vm.cyclistsBarPercentageSaved+'%'})},[_c('div',{ref:"cyclistsBar",staticClass:"cyclists-bar bar"})]),_c('div',{staticClass:"bar-contaienr",style:({width: _vm.pedestriansBarPercentageSaved+'%'})},[_c('div',{ref:"pedestriansBar",staticClass:"pedestrians-bar bar"})]),_c('div',{staticClass:"bar-contaienr",style:({width: _vm.carPassengersBarPercentageSaved+'%'})},[_c('div',{ref:"carPassengersBar",staticClass:"car-passengers-bar bar"})])]),_c('div',{staticClass:"info-container row"},[_c('div',{staticClass:"info info-two-wheelers col-md-3",class:{'col-sm-12': _vm.isWithSaved,
        'col-sm-6': !_vm.isWithSaved}},[_c('div',{staticClass:"info-title"},[(_vm.twoWheelersText !== '-')?_c('span',{staticClass:"info-label"},[_vm._v(_vm._s(_vm.twoWheelersText))]):_vm._e()]),_c('div',{staticClass:"info-content"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/surveyIcons/1.svg")}}),_c('span',{staticClass:"info-percentage"},[_vm._v(_vm._s(_vm.twoWheelerPercentageSaved))]),_c('span',{staticClass:"info-number"},[_vm._v("("+_vm._s(_vm._f("formatNumber")(_vm.savedData.twoWheelers))+")")])])]),_c('div',{staticClass:"info info-two-wheelers col-md-3",class:{'col-sm-12': _vm.isWithSaved,
        'col-sm-6': !_vm.isWithSaved}},[_c('div',{staticClass:"info-title"},[(_vm.cyclistsText !== '-')?_c('span',{staticClass:"info-label"},[_vm._v(_vm._s(_vm.cyclistsText))]):_vm._e()]),_c('div',{staticClass:"info-content"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/surveyIcons/2.svg")}}),_c('span',{staticClass:"info-percentage"},[_vm._v(_vm._s(_vm.cyclistsPercentageSaved))]),_c('span',{staticClass:"info-number"},[_vm._v("("+_vm._s(_vm._f("formatNumber")(_vm.savedData.cyclists))+")")])])]),_c('div',{staticClass:"info info-two-wheelers col-md-3",class:{'col-sm-12': _vm.isWithSaved,
        'col-sm-6': !_vm.isWithSaved}},[_c('div',{staticClass:"info-title"},[(_vm.pedestrianText !== '-')?_c('span',{staticClass:"info-label"},[_vm._v(_vm._s(_vm.pedestrianText))]):_vm._e()]),_c('div',{staticClass:"info-content"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/surveyIcons/3.svg")}}),_c('span',{staticClass:"info-percentage"},[_vm._v(_vm._s(_vm.pedestriansPercentageSaved))]),_c('span',{staticClass:"info-number"},[_vm._v("("+_vm._s(_vm._f("formatNumber")(_vm.savedData.pedestrians))+")")])])]),_c('div',{staticClass:"info info-two-wheelers col-md-3",class:{'col-sm-12': _vm.isWithSaved,
        'col-sm-6': !_vm.isWithSaved}},[_c('div',{staticClass:"info-title"},[(_vm.carPassengersText !== '-')?_c('span',{staticClass:"info-label"},[_vm._v(_vm._s(_vm.carPassengersText))]):_vm._e()]),_c('div',{staticClass:"info-content"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/surveyIcons/4.svg")}}),_c('span',{staticClass:"info-percentage"},[_vm._v(_vm._s(_vm.carPassengersPercentageSaved))]),_c('span',{staticClass:"info-number"},[_vm._v("("+_vm._s(_vm._f("formatNumber")(_vm.savedData.carPassengers))+")")])])])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }