<template>
  <div>
    <div class="container done-container">
      <h2 v-if="weAreDone !== '-'">{{weAreDone}}</h2>
      <p v-if="doneDescription !== '-'">{{doneDescription}}</p>
      <div class="share-network-list">
        <ShareNetwork
          v-for="network in networks"
          :network="network.network"
          :key="network.network"
          :style="{backgroundColor: network.color}"
          :url="sharing.url"
          :title="sharing.title"
          :description="sharing.description"
          :quote="sharing.quote"
          :hashtags="sharing.hashtags"
          :twitterUser="sharing.twitterUser"
        >
          <i :class="network.icon"></i>
          <span>{{ network.name }}</span>
        </ShareNetwork>
      </div>
      <div class="continue-container">
        <continue-button class="button" :disabled="false" :text="exitText"
          @click="exit" :showImage="false"></continue-button>
      </div>
    </div>
    <img src="@/assets/done-city.jpg" alt="city" class="done-city">
  </div>
</template>

<script>
import ContinueButton from '../components/continue-button/continue-button.vue';

export default {
  components: {
    ContinueButton,
  },
  /*eslint-disable */
  data() {
    return {
      sharing: {
        url: process.env.VUE_APP_DOMAIN_LINK,
        title: 'Complete this self driving car survery',
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s',
        quote: 'The cars we drive say a lot about us.',
        hashtags: 'selfDrivingCars',
      },
      networks: [
        // { network: 'baidu', name: 'Baidu', icon: 'fas fah fa-lg fa-paw', color: '#2529d8' },
        // { network: 'buffer', name: 'Buffer', icon: 'fab fah fa-lg fa-buffer', color: '#323b43' },
        // { network: 'email', name: 'Email', icon: 'far fah fa-lg fa-envelope', color: '#333333' },
        // { network: 'evernote', name: 'Evernote', icon: 'fab fah fa-lg fa-evernote', color: '#2dbe60' },
        { network: 'facebook', name: 'Facebook', icon: 'fab fah fa-lg fa-facebook-f', color: '#1877f2' },
        // { network: 'flipboard', name: 'Flipboard', icon: 'fab fah fa-lg fa-flipboard', color: '#e12828' },
        // { network: 'hackernews', name: 'HackerNews', icon: 'fab fah fa-lg fa-hacker-news', color: '#ff4000' },
        // { network: 'instapaper', name: 'Instapaper', icon: 'fas fah fa-lg fa-italic', color: '#428bca' },
        // { network: 'line', name: 'Line', icon: 'fab fah fa-lg fa-line', color: '#00c300' },
        { network: 'linkedin', name: 'LinkedIn', icon: 'fab fah fa-lg fa-linkedin', color: '#007bb5' },
        // { network: 'odnoklassniki', name: 'Odnoklassniki', icon: 'fab fah fa-lg fa-odnoklassniki', color: '#ed812b' },
        // { network: 'pinterest', name: 'Pinterest', icon: 'fab fah fa-lg fa-pinterest', color: '#bd081c' },
        // { network: 'pocket', name: 'Pocket', icon: 'fab fah fa-lg fa-get-pocket', color: '#ef4056' },
        // { network: 'quora', name: 'Quora', icon: 'fab fah fa-lg fa-quora', color: '#a82400' },
        // { network: 'reddit', name: 'Reddit', icon: 'fab fah fa-lg fa-reddit-alien', color: '#ff4500' },
        // { network: 'skype', name: 'Skype', icon: 'fab fah fa-lg fa-skype', color: '#00aff0' },
        // { network: 'sms', name: 'SMS', icon: 'far fah fa-lg fa-comment-dots', color: '#333333' },
        // { network: 'stumbleupon', name: 'StumbleUpon', icon: 'fab fah fa-lg fa-stumbleupon', color: '#eb4924' },
        // { network: 'telegram', name: 'Telegram', icon: 'fab fah fa-lg fa-telegram-plane', color: '#0088cc' },
        // { network: 'tumblr', name: 'Tumblr', icon: 'fab fah fa-lg fa-tumblr', color: '#35465c' },
        { network: 'twitter', name: 'Twitter', icon: 'fab fah fa-lg fa-twitter', color: '#1da1f2' },
        // { network: 'viber', name: 'Viber', icon: 'fab fah fa-lg fa-viber', color: '#59267c' },
        // { network: 'vk', name: 'Vk', icon: 'fab fah fa-lg fa-vk', color: '#4a76a8' },
        // { network: 'weibo', name: 'Weibo', icon: 'fab fah fa-lg fa-weibo', color: '#e9152d' },
        // { network: 'whatsapp', name: 'Whatsapp', icon: 'fab fah fa-lg fa-whatsapp', color: '#25d366' },
        // { network: 'wordpress', name: 'Wordpress', icon: 'fab fah fa-lg fa-wordpress', color: '#21759b' },
        // { network: 'xing', name: 'Xing', icon: 'fab fah fa-lg fa-xing', color: '#026466' },
        // { network: 'yammer', name: 'Yammer', icon: 'fab fah fa-lg fa-yammer', color: '#0072c6' },
      ],
    };
  },
 /* eslint-enable */
  computed: {
    translations() {
      return this.$store.state.translations;
    },
    weAreDone() {
      const def = 'We Are Done!';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'weAreDone');
      return t ? t.value : def;
    },
    doneDescription() {
      const def = `This test was part of a research study on the ethics of self-driving cars and what
        the general public thinks about some of the policies that the government might adopt.`;
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'doneDescription');
      return t ? t.value : def;
    },
    exitText() {
      const def = 'Exit';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'exitText');
      return t ? t.value : def;
    },
  },
  methods: {
    exit() {
      this.$router.push('/');
    },
  },
};
</script>

<style scoped>
.done-container{
  margin-top: 60px;
}
.done-container h2 {
  font-family: 'Lora', serif;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 46px;
  text-align: center;
  color: #080D25;
  z-index: 100;
}

.done-container p{
  font-family: 'Lato', serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 133.5%;
  text-align: center;
  color: #080D25;
  margin-top: 20px;
}

.done-city{
  width: 100%;
  position:fixed;
  bottom: 0px;
  z-index: -3;
}
.continue-container{
  position: fixed;
  bottom: 20%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 500;
}

@media only screen and (max-width: 900px) {
  .done-city{
    height: 60%;
    width: 800px;
    left: -200px;
  }

  .continue-container{
    bottom: 10%;
  }
}

.share-network-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1000px;
    margin: auto;
  }
  a[class^="share-network-"] {
    flex: none;
    color: #FFFFFF;
    background-color: #333;
    border-radius: 3px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    cursor: pointer;
    margin: 0 10px 10px 0;
  }
  a[class^="share-network-"] .fah {
    background-color: rgba(0, 0, 0, 0.2);
    padding: 10px;
    flex: 0 1 auto;
  }
  a[class^="share-network-"] span {
    padding: 0 10px;
    flex: 1 1 0%;
    font-weight: 500;
  }

</style>
