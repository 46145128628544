<template>
  <div class="container mt-5">
    <h2 class="text-center">Single Translation Admin Page</h2>
    <div class="form-group">
      <label for="exampleInputPassword1">Admin key</label>
      <input type="password" class="form-control" id="exampleInputPassword1" placeholder="Admin Key"
        v-model="adminKey">
    </div>
    <div class="form-group">
      <label for="countryCode">Country code</label>
      <input class="form-control" v-model="countryCode">
    </div>
    <div class="form-group">
      <label for="translationKey">Translation key</label>
      <input class="form-control" v-model="translationKey">
    </div>
    <div class="form-group">
      <label for="translationValue">Translation value</label>
      <input class="form-control" v-model="translationValue">
    </div>
    <button @click="submitData" class="btn btn-primary mt-4">Update</button>
    <div v-if="errorMessage" class="text-danger">
      {{errorMessage}}
    </div>
    <div v-if="success" class="text-success">
      Done
    </div>
  </div>
</template>

<script>
import adminApi from '../../api/admiApiService';

export default {
  data() {
    return {
      countryCode: null,
      translationKey: null,
      translationValue: null,
      adminKey: '',
      errorMessage: '',
      success: false,
    };
  },
  methods: {
    async submitData() {
      this.success = false;
      const response = await adminApi.sendSingleTranslation({
        adminKey: this.adminKey,
        countryCode: this.countryCode,
        translationKey: this.translationKey,
        translationValue: this.translationValue,
      });
      if (response.status === 200) {
        this.success = true;
      } else {
        this.errorMessage = response.data;
      }
    },
  },
};
</script>

<style>

</style>
