import baseApi from './baseApiService';
import ipService from './ipService';

export default {
  async getUserCountry() {
    const code = await ipService.getIpCode();
    // const response = await baseApi.get(`/country/getUserCountry/${ip}`);
    // if (response.data && response.status === 200) {
    //   return response.data;
    // }
    return code;
  },
  async getCountryData(countryCode) {
    const response = await baseApi.get(`/country/CountryData/${countryCode}`);
    if (response.data && response.status === 200) {
      return response.data;
    }
    return null;
  },
  async getTranslations(countryCode) {
    const response = await baseApi.get(`/country/Translations/${countryCode}`);
    if (response.data && response.status === 200) {
      return response.data;
    }
    return null;
  },
};
