<template>
  <div :class="{ 'scale-down': shouldScaleDown }">
    <div class="survey" id="container" :style="roadImage">
      <div class="cyclist" :style="cyclistImage"></div>
      <div class="truck" :style="TruckImage"></div>
      <div class="car" id="item" :style="carImage"></div>
      <div class="left-arrow" :style="leftArrow"
        v-if="state !== 'hitBike'"
        @click="moveLeftAction"
        @mousedown="moveLeft"
        @mouseup="clearIntervals"
        @mouseleave="clearIntervals"></div>
      <div class="right-arrow" :style="rightArrow"
        v-if="state !== 'hitTruck'"
        @click="moveRightAction"
        @mousedown="moveRight"
        @mouseup="clearIntervals"
        @mouseleave="clearIntervals"></div>
      <div class="crash1" :style="crash1"
        v-if="state === 'hitBike'"></div>
      <div class="crash2" :style="crash2"
        v-if="state === 'hitTruck'"></div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    this.shouldScaleDown = window.devicePixelRatio > 1;
  },
  data() {
    return {
      state: 'driving',
      currentScalePosition: 0,
      active: false,
      currentX: null,
      initialX: null,
      xOffSet: 0,
      score: 0,
      moveLeftInterval: null,
      moveRightInterval: null,
      shouldScaleDown: false,
    };
  },
  computed: {
    roadImage() {
      return {
        // eslint-disable-next-line global-require
        backgroundImage: `url(${require('@/assets/Road2.png')})`,
      };
    },
    cyclistImage() {
      if (this.state === 'hitBike') {
        return {
          // eslint-disable-next-line global-require
          backgroundImage: `url(${require('@/assets/Cyclist.png')})`,
          transform: 'rotate(20deg)',
        };
      }
      return {
        // eslint-disable-next-line global-require
        backgroundImage: `url(${require('@/assets/Cyclist.png')})`,
      };
    },
    TruckImage() {
      if (this.state === 'hitTruck') {
        return {
          // eslint-disable-next-line global-require
          backgroundImage: `url(${require('@/assets/Truck.png')})`,
          transform: 'rotate(-10deg) translateX(18px)',
        };
      }
      return {
        // eslint-disable-next-line global-require
        backgroundImage: `url(${require('@/assets/Truck.png')})`,
      };
    },
    carImage() {
      return {
        // eslint-disable-next-line global-require
        backgroundImage: `url(${require('@/assets/SelfDriving_car.png')})`,
      };
    },
    leftArrow() {
      return {
        // eslint-disable-next-line global-require
        backgroundImage: `url(${require('@/assets/left-arrow.png')})`,
      };
    },
    rightArrow() {
      return {
        // eslint-disable-next-line global-require
        backgroundImage: `url(${require('@/assets/right-arrow.png')})`,
      };
    },
    crash1() {
      return {
        // eslint-disable-next-line global-require
        backgroundImage: `url(${require('@/assets/Crash1.png')})`,
      };
    },
    crash2() {
      return {
        // eslint-disable-next-line global-require
        backgroundImage: `url(${require('@/assets/Crash2.png')})`,
      };
    },
  },
  watch: {
    score(val) {
      this.$emit('score-change', val);
    },
  },
  methods: {
    clearIntervals() {
      if (this.moveLeftInterval) {
        clearInterval(this.moveLeftInterval);
        this.moveLeftInterval = null;
      }
      if (this.moveRightInterval) {
        clearInterval(this.moveRightInterval);
        this.moveRightInterval = null;
      }
    },
    moveLeftAction() {
      this.$store.state.stepsCount -= 1;
      const dragItem = document.querySelector('#item');
      this.currentX -= 115 / 30;
      if (this.currentX < 0) {
        this.currentX = this.currentX < -115 ? -115 : this.currentX;
      } else if (this.currentX > 0) {
        this.currentX = this.currentX > 115 ? 115 : this.currentX;
      }
      this.xOffSet = this.currentX;
      this.setTranslate(this.currentX, dragItem);
    },
    moveLeft() {
      this.moveLeftInterval = setInterval(() => {
        this.moveLeftAction();
      }, 200);
    },
    moveRightAction() {
      this.$store.state.stepsCount += 1;
      const dragItem = document.querySelector('#item');
      this.currentX += 115 / 30;
      if (this.currentX < 0) {
        this.currentX = this.currentX < -115 ? -115 : this.currentX;
      } else if (this.currentX > 0) {
        this.currentX = this.currentX > 115 ? 115 : this.currentX;
      }
      this.xOffSet = this.currentX;
      this.setTranslate(this.currentX, dragItem);
    },
    moveRight() {
      this.moveRightInterval = setInterval(() => {
        this.moveRightAction();
      }, 200);
    },
    dragStart(e) {
      const dragItem = document.querySelector('#item');
      this.initialX = e.clientX - this.xOffSet;

      if (e.target === dragItem) {
        this.active = true;
      }
    },
    dragEnd() {
      this.initialX = this.currentX;
      this.active = false;
    },
    drag(e) {
      const dragItem = document.querySelector('#item');
      if (this.active) {
        e.preventDefault();
        this.currentX = e.clientX - this.initialX;
        if (this.currentX < 0) {
          this.currentX = this.currentX < -115 ? -115 : this.currentX;
        } else if (this.currentX > 0) {
          this.currentX = this.currentX > 115 ? 115 : this.currentX;
        }
        if (this.currentX === -115) {
          this.$store.state.stepsCount = -30;
        } else if (this.currentX === 115) {
          this.$store.state.stepsCount = 30;
        } else {
          this.$store.state.stepsCount = parseInt(this.currentX / (115 / 30), 10);
        }
        this.xOffSet = this.currentX;
        this.setTranslate(this.currentX, dragItem);
      }
    },
    setTranslate(xPos, el) {
      if (Math.round(xPos) === -115) {
        this.state = 'hitBike';
        this.clearIntervals();
        this.score = -100;
        // eslint-disable-next-line no-param-reassign
        el.style.transform = `translateX(${xPos}px) rotate(-18deg) translateY(-20px)`;
      } else if (Math.round(xPos) === 115) {
        this.clearIntervals();
        this.state = 'hitTruck';
        this.score = 100;
        // eslint-disable-next-line no-param-reassign
        el.style.transform = `translateX(${xPos}px) rotate(18deg) translateY(-20px)`;
      } else {
        // eslint-disable-next-line no-param-reassign
        el.style.transform = `translateX(${xPos}px)`;
        this.state = 'driving';
        if (xPos === 0) {
          this.score = 0;
        } else if (xPos < 0) {
          this.score = Math.round((xPos / 115) * 100);
        } else {
          this.score = Math.round((xPos / 115) * 100);
        }
      }
    },
  },
  mounted() {
    const container = document.querySelector('#container');

    container.removeEventListener('mousedown', this.dragStart);
    container.addEventListener('mousedown', this.dragStart, false);

    container.removeEventListener('mouseup', this.dragEnd);
    container.addEventListener('mouseup', this.dragEnd, false);

    container.removeEventListener('mousemove', this.drag);
    container.addEventListener('mousemove', this.drag, false);
  },
};
</script>

<style scoped>
.survey{
  width: 900px;
  height: 520px;
  margin: 0 auto;
  margin-top: 30px;
  background-size: contain;
  position: relative;
}
.cyclist{
  position: absolute;
  width: 90px;
  height: 140px;
  background-size: contain;
  background-repeat: no-repeat;
  left: 224px;
  top: 180px;
}
.truck{
  position: absolute;
  width: 180px;
  height: 400px;
  background-size: contain;
  background-repeat: no-repeat;
  left: 618px;
  top: 70px;
}

.car{
  position: absolute;
  width: 400px;
  height: 400px;
  background-size: contain;
  background-repeat: no-repeat;
  left: 258px;
  top: 170px;
}

.left-arrow{
  position: absolute;
  width: 60px;
  height: 60px;
  background-size: contain;
  background-repeat: no-repeat;
  left: 290px;
  top: 250px;
}

.right-arrow{
  position: absolute;
  width: 60px;
  height: 60px;
  background-size: contain;
  background-repeat: no-repeat;
  left: 542px;
  top: 250px;
}

.crash1{
  position: absolute;
  width: 150px;
  height: 150px;
  background-size: contain;
  background-repeat: no-repeat;
  left: 230px;
  top: 150px;
}
.crash2{
  position: absolute;
  width: 150px;
  height: 150px;
  background-size: 180px;
  background-repeat: no-repeat;
  left: 540px;
  top: 120px;
}

.scale-down{
  transform: scale(0.8);
  margin-top: -40px;
  margin-bottom: -40px;
}
</style>
