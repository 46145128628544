<template>
  <div>
    <div class="survey" id="container" :style="roadImage">
      <div class="cyclist" :style="cyclistImage"></div>
      <div class="truck" :style="TruckImage"></div>
      <div class="car" id="item" :style="carImage"></div>
      <div class="left-arrow" :style="leftArrow"
        v-if="state !== 'hitBike'"
        @click="moveLeft"></div>
      <div class="right-arrow" :style="rightArrow"
        v-if="state !== 'hitTruck'"
        @click="moveRight"></div>
      <div class="crash1" :style="crash1"
        v-if="state === 'hitBike'"></div>
      <div class="crash2" :style="crash2"
        v-if="state === 'hitTruck'"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      state: 'driving',
      currentScalePosition: 0,
      active: false,
      currentX: null,
      initialX: null,
      xOffSet: 0,
      score: 0,
    };
  },
  computed: {
    roadImage() {
      return {
        // eslint-disable-next-line global-require
        backgroundImage: `url(${require('@/assets/Road2.png')})`,
      };
    },
    cyclistImage() {
      if (this.state === 'hitBike') {
        return {
          // eslint-disable-next-line global-require
          backgroundImage: `url(${require('@/assets/Cyclist.png')})`,
          transform: 'rotate(20deg)',
        };
      }
      return {
        // eslint-disable-next-line global-require
        backgroundImage: `url(${require('@/assets/Cyclist.png')})`,
      };
    },
    TruckImage() {
      if (this.state === 'hitTruck') {
        return {
          // eslint-disable-next-line global-require
          backgroundImage: `url(${require('@/assets/Truck.png')})`,
          transform: 'rotate(-10deg) translateX(18px)',
        };
      }
      return {
        // eslint-disable-next-line global-require
        backgroundImage: `url(${require('@/assets/Truck.png')})`,
      };
    },
    carImage() {
      return {
        // eslint-disable-next-line global-require
        backgroundImage: `url(${require('@/assets/SelfDriving_car.png')})`,
      };
    },
    leftArrow() {
      return {
        // eslint-disable-next-line global-require
        backgroundImage: `url(${require('@/assets/left-arrow.png')})`,
      };
    },
    rightArrow() {
      return {
        // eslint-disable-next-line global-require
        backgroundImage: `url(${require('@/assets/right-arrow.png')})`,
      };
    },
    crash1() {
      return {
        // eslint-disable-next-line global-require
        backgroundImage: `url(${require('@/assets/Crash1.png')})`,
      };
    },
    crash2() {
      return {
        // eslint-disable-next-line global-require
        backgroundImage: `url(${require('@/assets/Crash2.png')})`,
      };
    },
  },
  watch: {
    score(val) {
      this.$emit('score-change', val);
    },
  },
  methods: {
    moveLeft() {
      this.$store.state.stepsCount -= 1;
      const dragItem = document.querySelector('#item');
      this.currentX -= 46 / 30;
      if (this.currentX < 0) {
        this.currentX = this.currentX < -46 ? -46 : this.currentX;
      } else if (this.currentX > 0) {
        this.currentX = this.currentX > 46 ? 46 : this.currentX;
      }
      this.xOffSet = this.currentX;
      this.setTranslate(this.currentX, dragItem);
    },
    moveRight() {
      this.$store.state.stepsCount += 1;
      const dragItem = document.querySelector('#item');
      this.currentX += 46 / 30;
      if (this.currentX < 0) {
        this.currentX = this.currentX < -46 ? -46 : this.currentX;
      } else if (this.currentX > 0) {
        this.currentX = this.currentX > 46 ? 46 : this.currentX;
      }
      this.xOffSet = this.currentX;
      this.setTranslate(this.currentX, dragItem);
    },
    dragStart(e) {
      const dragItem = document.querySelector('#item');

      this.initialX = e.touches[0].clientX - this.xOffSet;

      if (e.target === dragItem) {
        this.active = true;
      }
    },
    dragEnd() {
      this.initialX = this.currentX;
      this.active = false;
    },
    drag(e) {
      const dragItem = document.querySelector('#item');
      if (this.active) {
        e.preventDefault();
        this.currentX = e.touches[0].clientX - this.initialX;
        if (this.currentX < 0) {
          this.currentX = this.currentX < -46 ? -46 : this.currentX;
        } else if (this.currentX > 0) {
          this.currentX = this.currentX > 46 ? 46 : this.currentX;
        }
        if (this.currentX === -46) {
          this.$store.state.stepsCount = -30;
        } else if (this.currentX === 46) {
          this.$store.state.stepsCount = 30;
        } else {
          this.$store.state.stepsCount = parseInt(this.currentX / (46 / 30), 10);
        }
        this.xOffSet = this.currentX;
        this.setTranslate(this.currentX, dragItem);
      }
    },
    setTranslate(xPos, el) {
      if (Math.round(xPos) === -46) {
        this.state = 'hitBike';
        this.score = -100;
        // eslint-disable-next-line no-param-reassign
        el.style.transform = `translateX(${xPos}px) rotate(-10deg) translateY(-8px)`;
      } else if (Math.round(xPos) === 46) {
        this.state = 'hitTruck';
        this.score = 100;
        // eslint-disable-next-line no-param-reassign
        el.style.transform = `translateX(${xPos}px) rotate(18deg) translateY(-20px)`;
      } else {
        // eslint-disable-next-line no-param-reassign
        el.style.transform = `translateX(${xPos}px)`;
        this.state = 'driving';
        if (xPos === 0) {
          this.score = 0;
        } else if (xPos < 0) {
          this.score = Math.round((xPos / 46) * 100);
        } else {
          this.score = Math.round((xPos / 46) * 100);
        }
      }
    },
  },
  mounted() {
    const container = document.querySelector('#container');

    container.removeEventListener('touchstart', this.dragStart);
    container.addEventListener('touchstart', this.dragStart, false);

    container.removeEventListener('touchend', this.dragEnd);
    container.addEventListener('touchend', this.dragEnd, false);

    container.removeEventListener('touchmove', this.drag);
    container.addEventListener('touchmove', this.drag, false);
  },
};
</script>

<style scoped>
.survey{
  width: 360px;
  height: 211px;
  margin: 0 auto;
  margin-top: 30px;
  background-size: contain;
  position: relative;
}
.cyclist{
  position: absolute;
  width: 20px;
  height: 50px;
  background-size: contain;
  background-repeat: no-repeat;
  left: 90px;
  top: 70px;
}
.truck{
  position: absolute;
  width: 75px;
  height: 160px;
  background-size: contain;
  background-repeat: no-repeat;
  left: 247px;
  top: 29px;
}
.car{
  position: absolute;
  width: 150px;
  height: 150px;
  background-size: contain;
  background-repeat: no-repeat;
  left: 108px;
  top: 65px;
}
.left-arrow{
  position: absolute;
  width: 40px;
  height: 40px;
  background-size: contain;
  background-repeat: no-repeat;
  left: 109px;
  top: 190px;
}

.right-arrow{
  position: absolute;
  width: 40px;
  height: 40px;
  background-size: contain;
  background-repeat: no-repeat;
  left: 207px;
  top: 190px;
}

.crash1{
  position: absolute;
  width: 55px;
  height: 55px;
  background-size: contain;
  background-repeat: no-repeat;
  left: 86px;
  top: 60px;
}
.crash2{
  position: absolute;
  width: 55px;
  height: 55px;
  background-size: 68px;
  background-repeat: no-repeat;
  left: 228px;
  top: 44px;
}
</style>
