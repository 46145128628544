<template>
  <div id="app" class="d-flex flex-column min-vh-100">
    <div class="wrapper flex-grow-1">
      <router-view/>
    </div>
    <div class="container d-flex justify-content-between app-footer">
      <div class="text-center p-2">
        Made by
        <a href="https://www.mpib-berlin.mpg.de/chm">
          Center for Humans and Machines
        </a>
        at
        <a href="https://www.mpib-berlin.mpg.de/en">
          Max Planck Institute for Human Development
        </a>
      </div>
      <div class="p-2">
        <router-link to="/imprint">
          Imprint
        </router-link>
        <span  class="mx-2">|</span>
        <router-link to="/policy">
          Privacy Policy
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {

};
</script>

<style>
:root{
  --two-wheeler-color: #E90A90;
  --cyclists-color: #F66512;
  --pedestrians-color: #780AE9;
  --car-passengers-color: #00A86B;
}

.wrap{
  min-height: 100vh;
  position: relative;
  padding-bottom: 60px;
  box-sizing: border-box;
}

.app-footer{
  font-size: 12px;
}
</style>
