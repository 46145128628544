<template>
  <div class="container">
    <h1 class="text-center mt-3"><b>Imprint/Provider Identification</b></h1>
    <p class="mt-3">
      <b>
        The following provides mandatory data concerning the provider of this website, obligations with regard to data protection, as well as other important legal references involving the Internet site of the Max Planck Society (https://www.mpg.de) as required by German law.
      </b>
    </p>
    <h2>
      Provider
    </h2>
    <p>
      The provider of this Internet site within the legal meaning of the term is the
      registered association Max Planck Society for the Advancement of Science e.V.
    </p>
    <h2>
      Address
    </h2>
    <p>
      Max-Planck-Gesellschaft zur Förderung der Wissenschaften e.V.
      <br/>
      Hofgartenstrasse 8
      <br/>
      D-80539 Munich
      <br/>
      Germany
      <br/>
      <br/>
      Phone: +49 89 2108-0
      <br/>
      Internet: https://www.mpg.de
    </p>
    <h2>
      Register of Societies and Associations
    </h2>
    <p>
      The Max Planck Society is registered in the Official Register of Societies and
      Associations at Berlin-Charlottenburg Local Court under the register number VR 13378 B.
    </p>
    <h2>
      Representatives
    </h2>
    <p>
      The Max Planck Society is legally represented by its Board of Directors which, in turn,
      is represented by the President of the Society, Prof. Dr. Martin Stratmann and by Secretary
      General Rüdiger Willems.
    </p>
    <h2>
      Value Added Tax Identification Number
    </h2>
    <p>
      The value added tax identification number of the Max Planck Society is DE 129517720.
    </p>
    <h2>
      Editors
    </h2>
    <p>
      Responsible editor for the contents of the Max Planck Society's
      website with regard to media law:
      <br>
      <br>
      Dr. Christina Beck
      <br>
      Max-Planck-Gesellschaft zur Foerderung der Wissenschaften e.V.
      <br>
      Hofgartenstrasse 8
      <br>
      80539 Munich
      <br>
      Germany
    </p>
    <h2>
      Legal Structure
    </h2>
    <p>
      The Max Planck Society is a non-profit research facility which is organized as
      a registered association. All of the institutes and facilities of the Max Planck
      Society are largely autonomous in terms of organization and research, but as
      a rule have no legal capacity of their own.
    </p>
    <h2>
      Foreign Language Pages
    </h2>
    <p>
      To the extent that parts of this Internet site are offered in languages other than German,
      this represents a service exclusively for staff and guests of the Max Planck Society who are
      not proficient in German.
    </p>
    <h2>
      Liability for Contents of Online Information
    </h2>
    <p>
      As the provider of contents in accordance with Section 7 Paragraph 1 of the Tele-Media Law,
      the MaxPlanck Society shall be responsible for any contents which it makes available for use
      in accordance with general legal provisions. The Max Planck Society makes every effort to
      providetimely and accurate information on this Web site. Nevertheless, errors and
      inaccuracies cannot be completely ruled out. Therefore, the Max Planck Society does not
      assume any liability for the relevance, accuracy, completeness or quality of the information
      provided. The Max Planck Society shall not be liable for damage of a tangible or intangible
      nature caused directly or indirectly through the use or failure to use the information offered
      and/or through the use of faulty or incomplete information unless it is verifiably culpable
      of intent or gross negligence. The same shall apply to any downloadable software available
      free of charge. The Max Planck Society reserves the right to modify, supplement, or delete
      any or all of the information offered on its Internet site, or to temporarily or permanently
      cease publication thereof without prior and separate notification.
    </p>
    <h2>
      Links to Internet Sites of Third Parties
    </h2>
    <p>
      This Internet site includes links to external pages. These external links are designated as
      follows: . The respective provider shall be responsible for the contents of any linked
      externalpages. In establishing the initial link, the Max Planck Society has reviewed
      the respective external content in order to determine whether such link entailed possible
      civil or criminal responsibility. However, a constant review of linked external pages is
      unreasonable without concrete reason to believe that a violation of the law may be involved.
      If the Max Planck Society determines such or it is pointed out by others that an external
      offer to which it is connected via a link entails civil or criminal responsibility, then
      the Max Planck Society will immediately eliminate any link to this offer. The Max Planck
      Society expressly dissociates itself from such contents.
    </p>
    <h2>
      Copyright
    </h2>
    <p>
      The layout, graphics employed and any other contents on the homepage of the Max Planck
      Society Internet site are protected by copyright law.
    </p>
    <p>
      © Max-Planck-Gesellschaft zur Förderung der Wissenschaften e.V., Munich. All rights reserved.
    </p>
  </div>
</template>

<script>
export default {
  mounted() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  },
};
</script>

<style scoped>
h1, h2, p {
  font-family: Lora;

}
</style>
