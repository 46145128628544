<template>
  <div class="radio-button" :class="{selected: isSelected}" @click="onClick">
    {{text}}
  </div>
</template>

<script>
export default {
  props: {
    isSelected: {
      type: Boolean,
      required: true,
      default: false,
    },
    text: {
      type: String,
      required: true,
    },
  },
  methods: {
    onClick() {
      this.$emit('click');
    },
  },
};
</script>

<style scoped>
.radio-button{
  width: 100%;
  height: 100%;
  background-color: #EDEDF4;
  border-radius: 10px;
  min-height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: center;

  color: #080D25;
}
.radio-button:not(.selected):hover {
  background-color: #D1D9FF;
}
.selected {
  background-color: #1C35AC;
  color: white;
}
</style>
