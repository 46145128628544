<template>
  <div class="container mt-5">
    <h2 class="text-center">Proportion Data Admin Page</h2>
    <div class="form-group">
      <label for="exampleInputPassword1">Admin key</label>
      <input type="password" class="form-control" id="exampleInputPassword1" placeholder="Admin Key"
        v-model="adminKey">
    </div>
    <div class="form-group">
      <label for="exampleInputEmail1">Car passenger proportion</label>
      <input class="form-control" v-model.number="carPasengerProportion">
    </div>
    <div class="form-group">
      <label for="exampleInputEmail1">Fatality reduction proportion</label>
      <input class="form-control" v-model.number="fatalityReductionProportion">
    </div>
    <button @click="submitData" class="btn btn-primary mt-4">Update</button>
  </div>
</template>

<script>
import adminApi from '../../api/admiApiService';

export default {
  data() {
    return {
      carPasengerProportion: null,
      fatalityReductionProportion: null,
      adminKey: '',
      errorMessage: '',
    };
  },
  methods: {
    async submitData() {
      const response = await adminApi.sendConfigData({
        carPassengerProportion: this.carPasengerProportion,
        fatalityReductionProportion: this.fatalityReductionProportion,
        adminKey: this.adminKey,
      });
      if (response.status === 200) {
        this.$router.push('/');
      } else {
        this.errorMessage = response.data;
      }
    },
  },
};
</script>

<style>

</style>
