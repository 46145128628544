<template>
  <div class="container final-container">
    <h2 v-if="finalSection !== '-'">
      {{finalSection}}
    </h2>
    <p v-if="finalSectionDescription !== '-'">
      {{finalSectionDescription}}
    </p>
    <div class="continue-container">
      <continue-button :disabled="false" :text="continueText"
        @click="next" :showImage="true"></continue-button>
    </div>
  </div>
</template>

<script>
import ContinueButton from '../components/continue-button/continue-button.vue';

export default {
  components: {
    ContinueButton,
  },
  mounted() {
    this.scrollToTop();
  },
  computed: {
    translations() {
      return this.$store.state.translations;
    },
    finalSection() {
      const def = 'The Final Section';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'finalSection');
      return t ? t.value : def;
    },
    finalSectionDescription() {
      const def = 'This is the final section of this survey where we would like to learn a few details about you.';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'finalSectionDescription');
      return t ? t.value : def;
    },
    continueText() {
      const def = 'Continue';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'continue');
      return t ? t.value : def;
    },
  },
  methods: {
    async next() {
      await this.$store.dispatch('createDemographicSurvey', {
        userSessionId: this.$store.state.userSessionInfo.userSessionId,
      });
      this.$router.push('/transport');
    },
    // next() {
    //   this.$router.push('/transport');
    // },
    scrollToTop() {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    },
  },
};
</script>

<style scoped>
.final-container{
  margin-top: 80px;
}
.final-container h2{
  font-family: 'Lora', serif;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 46px;
  color: #080D25;
}

.final-container p{
  font-family: 'Lato', serif;
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  line-height: 133.5%;
  color: #080D25;
  margin: 26px 0px;
}

.continue-container{
  display: flex;
  margin-top: 30px;
  justify-content: flex-start;
  align-content: center;
}
</style>
