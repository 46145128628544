<template>
  <div class="radio-button" :class="{selected: isSelected,
    dis: disabled}" @click="onClick">
    {{text}}
    <img v-if="showImage" class="arrow" src="@/assets/surveyIcons/Arrow.svg" alt="arrow">
  </div>
</template>

<script>
export default {
  props: {
    isSelected: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
      default: true,
    },
    showImage: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    onClick() {
      if (!this.disabled) {
        this.$emit('click');
      }
    },
  },
};
</script>

<style scoped>
.radio-button{
  width: 207px;
  height: 60px;
  background-color: #1C35AC;
  border-radius: 10px;
  min-height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #FFFFFF;
  margin-bottom: 60px;
  margin-top: 25px;
}
.radio-button:not(.selected):hover {
  background-color: #2744D0;
}
.selected {
  background-color: #1C35AC;
  color: white;
}
.dis{
  background-color: #ABAEBF !important;
}
.arrow{
  margin-left: 8px;
  position: relative;
  top: 2px;
}
</style>
