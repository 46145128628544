/* eslint no-underscore-dangle: 0 */
import Vue from 'vue';
import Vuex from 'vuex';
import admiApiService from '../api/admiApiService';
import countryApiService from '../api/countryApiService';
import surveryApiService from '../api/surveyApiService';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userSessionInfo: {
      userSessionId: null,
      cookie: null,
      fingerPrint: null,
      userIp: null,
    },
    staticSurvey: null,
    demographicSurvey: null,
    interactiveSurvey: null,
    firstStaticPageData: null,
    secondStaticPageData: null,
    thirdStaticPageData: null,
    solutionStaticPagaData: null,
    firstInteractivePageData: null,
    secondInteractivePageData: null,
    thirdInteractivePageData: null,
    currentCountry: null,
    userCountry: null,
    translations: null,
    userTransport: null,
    userInfo: null,
    feedback: null,
    stepsCount: 0,
    interactiveSurveyOptions: [1, 2, 3],
    staticSurveyOptions: [1, 2, 3],
    staticSurveyIndex: 0,
    interactiveSurveyIndex: 0,
    appConfigRatio: {
      fatalityReductionProportion: 0.5,
      carPassengerProportion: 0.5,
    },
    continueBtn: false,
  },
  mutations: {
    setUserSessionInfo(state, data) {
      state.userSessionInfo.userSessionId = data._id;
      state.userSessionInfo.cookie = data.cookie;
      state.userSessionInfo.fingerPrint = data.fingerPrint;
      state.userSessionInfo.userIp = data.userIp;
      state.staticSurveyOptions = data.staticPagesOrder;
      state.interactiveSurveyOptions = data.interactivePagesOrder;
    },
    resetState(state) {
      state.interactiveSurveyId = null;
      state.interactiveSurvey = {
        score1: null,
        score2: null,
        score3: null,
      };
      state.stepsCount = 0;
      state.interactiveSurveyOptions = [1, 2, 3];
      state.staticSurveyOptions = [1, 2, 3];
    },
    setFirstPageData(state, data) {
      state.firstStaticPageData = data;
    },
    setSecondPageData(state, data) {
      state.secondStaticPageData = data;
    },
    setThirdPageData(state, data) {
      state.thirdStaticPageData = data;
    },
    setStaticSurveyIndex(state, data) {
      state.staticSurveyIndex = data;
    },
    incStaticSurveyIndex(state) {
      state.staticSurveyIndex += 1;
    },
    resetStaticSurveyIndex(state) {
      state.staticSurveyIndex = 0;
    },
    setInteractiveSurveyIndex(state, data) {
      state.interactiveSurveyIndex = data;
    },
    incInteractiveSurveyIndex(state) {
      state.interactiveSurveyIndex += 1;
    },
    resetInteractiveSurveyIndex(state) {
      state.interactiveSurveyIndex = 0;
    },
    setSolutionPageData(state, data) {
      state.solutionStaticPagaData = data;
    },
    setUserCountry(state, country) {
      state.userCountry = country;
      sessionStorage.setItem('countryCode', country);
    },
    setCountryData(state, country) {
      state.currentCountry = country;
    },
    setTranslations(state, tData) {
      state.translations = tData;
    },
    submitUserTransport(state, data) {
      state.userTransport = data;
    },
    submitUserInfo(state, data) {
      state.userInfo = data;
    },
    submitFeedback(state, data) {
      state.feedback = data;
    },
    createDemographicSurvey(state, demographicSurvey) {
      if (demographicSurvey) {
        state.demographicSurvey = true;
      }
    },
    createStaticSurvey(state, staticSurvey) {
      if (staticSurvey) {
        state.staticSurvey = true;
      }
    },
    createInteractiveSurvey(state, interactiveSurvey) {
      if (interactiveSurvey) {
        state.interactiveSurvey = true;
      }
    },
    setInteractive1Score(state, score) {
      state.firstInteractivePageData = score;
    },
    setInteractive2Score(state, score) {
      state.secondInteractivePageData = score;
    },
    setInteractive3Score(state, score) {
      state.thirdInteractivePageData = score;
    },
    setAppCOnfigRatio(state, ratio) {
      state.appConfigRatio.fatalityReductionProportion = ratio.fatalityReductionProportion;
      state.appConfigRatio.carPassengerProportion = ratio.carPassengerProportion;
    },
  },
  actions: {
    async resetState({ commit }) {
      commit('resetState');
    },
    async setStaticSurveyIndex({ commit }, data) {
      commit('setStaticSurveyIndex', data);
    },
    async incStaticSurveyIndex({ commit }) {
      commit('incStaticSurveyIndex');
    },
    async resetStaticSurveyIndex({ commit }) {
      commit('resetStaticSurveyIndex');
    },
    async setInteractiveSurveyIndex({ commit }, data) {
      commit('setInteractiveSurveyIndex', data);
    },
    async incInteractiveSurveyIndex({ commit }) {
      commit('incInteractiveSurveyIndex');
    },
    async resetInteractiveSurveyIndex({ commit }) {
      commit('resetInteractiveSurveyIndex');
    },
    async submitUserTransport({ commit }, data) {
      commit('submitUserTransport', data);
      surveryApiService.sendTransportPage(data);
    },
    async submitFirstPage({ commit }, data) {
      commit('setFirstPageData', data);
      surveryApiService.sendFirstPage(data);
    },
    async submitSecondPage({ commit }, data) {
      commit('setSecondPageData', data);
      surveryApiService.sendSecondPage(data);
    },
    async submitThirdPage({ commit }, data) {
      commit('setThirdPageData', data);
      surveryApiService.sendThirdPage(data);
    },
    async submitSolutionPage({ commit }, data) {
      commit('setSolutionPageData', data);
      await surveryApiService.sendSolutionPage(data);
    },
    async submitUserInfo({ commit }, data) {
      commit('submitUserInfo', data);
      surveryApiService.sendUserInfo(data);
    },
    submitFeedback({ commit }, data) {
      commit('submitFeedback', data);
      surveryApiService.sendFeedback(data);
    },
    async createSurvey({ commit }, data) {
      const staticSurvey = await surveryApiService.createSurvey(data);
      commit('createStaticSurvey', staticSurvey);
    },
    async createDemographicSurvey({ commit }, data) {
      const demographicSurvey = await surveryApiService.createDemographicSurvey(data);
      commit('createDemographicSurvey', demographicSurvey);
    },
    async initialSetupInteractive({ commit }, data) {
      const interactiveSurvey = await surveryApiService.createInteractiveSurvey(data);
      commit('createInteractiveSurvey', interactiveSurvey);
    },
    async saveInteractive1({ commit }, data) {
      commit('setInteractive1Score', data);
      surveryApiService.sendInteractiveSurveyPage1(data);
    },
    async saveInteractive2({ commit }, data) {
      commit('setInteractive2Score', data);
      surveryApiService.sendInteractiveSurveyPage2(data);
    },
    async saveInteractive3({ commit }, data) {
      commit('setInteractive3Score', data);
      surveryApiService.sendInteractiveSurveyPage3(data);
    },
    async initialSetup({ commit }) {
      let code = sessionStorage.getItem('countryCode');
      if (!code || code === 'null') {
        const userCountry = await countryApiService.getUserCountry();
        await commit('setUserCountry', userCountry);
        code = userCountry;
      }
      const dataCountry = await countryApiService.getCountryData(code);
      await commit('setCountryData', dataCountry);
      const tData = await countryApiService.getTranslations(code);
      await commit('setTranslations', tData);
      const appConfigData = await admiApiService.getAppConfigData();
      await commit('setAppCOnfigRatio', appConfigData);
      // const surveyId = localStorage.getItem('surveyId');
      // if (surveyId) {
      //   commit('setSurveyId', surveyId);
      // }
      const userSessionInfo = await surveryApiService.sendUserSessionInfo();
      if (userSessionInfo) {
        await commit('setUserSessionInfo', userSessionInfo);
      }
    },
  },
  modules: {
  },
});
