<template>
  <div class="container page-container">
    <div class="before-self-driving-container">
      <h2 v-if="solutionsSaw !== '-'">{{solutionsSaw}}</h2>
      <div class="hours-chart">
        <h4 v-if="roadUsage !== '-'">{{roadUsage}}</h4>
        <chart v-if="countryData" :chartTitle="totalPersonHours"
          :twoWheelers="countryData.twoWheelerHours" :cyclists="countryData.cyclistsHours"
          :pedestrians="countryData.pedestrianHours"
          :carPassengers="countryData.carPassengersHours"
          :totalNumber="totalHoursNumber"
        />
      </div>
      <hr>
      <div class="fatal-chart">
        <h4 v-if="dieTitle !== '-'">{{dieTitle}}</h4>
        <chart v-if="countryData" :chartTitle="totalFatalitiesText"
          :twoWheelers="countryData.twoWheelerFatalities"
          :cyclists="countryData.cyclistsFatalities"
          :pedestrians="countryData.pedestrianFatalities"
          :carPassengers="countryData.carPassengerFatalities"
          :totalNumber="totalFatalities"/>
      </div>
    </div>
    <div class="after-self-driving-container">
      <h2 v-if="imagineSave !== '-'">{{imagineSave}}
        <br/>{{whoToSave}}</h2>
      <div class="solution-label" v-if="solutionText !== '-'">{{solutionText}} 1</div>
      <div class="solution-label-bottom" v-if="solution1Description !== '-'">
        {{solution1Description}}
      </div>
      <chart v-if="afterData_1"
        :twoWheelers="afterData_1.twoWheelers"
        :cyclists="afterData_1.cyclists"
        :pedestrians="afterData_1.pedestrians"
        :carPassengers="afterData_1.carPassengers"
        :saved="afterData_1.saved"
        :savedData="savedData"
        :chartTitle="totalFatalitiesText"
        :totalNumber="totalFatalitiesHalf"
      />
      <div class="solution-label" v-if="solutionText !== '-'">{{solutionText}} 2</div>
      <div class="solution-label-bottom" v-if="solution2Description !== '-'">
        {{solution2Description}}
      </div>
      <chart v-if="afterData_2"
        :twoWheelers="afterData_2.twoWheelers"
        :cyclists="afterData_2.cyclists"
        :pedestrians="afterData_2.pedestrians"
        :carPassengers="afterData_2.carPassengers"
        :saved="afterData_2.saved"
        :savedData="savedData2"
        :chartTitle="totalFatalitiesText"
        :totalNumber="totalFatalitiesHalf"/>
      <div class="solution-label" v-if="solutionText !== '-'">{{solutionText}} 3</div>
      <div class="solution-label-bottom" v-if="solution3Description !== '-'">
        {{solution3Description}}
      </div>
      <chart v-if="afterData_3"
        :twoWheelers="afterData_3.twoWheelers"
        :cyclists="afterData_3.cyclists"
        :pedestrians="afterData_3.pedestrians"
        :carPassengers="afterData_3.carPassengers"
        :saved="afterData_3.saved"
        :savedData="savedData3"
        :chartTitle="totalFatalitiesText"
        :totalNumber="totalFatalitiesHalf"/>
    </div>
    <div class="understanding" v-if="yourOpinionText !== '-'">{{yourOpinionText}}</div>
    <div class="fairness" v-if="willingToBuy !== '-'">{{willingToBuy}}</div>
    <div class="row fainrness-label">
      <div class="unfair col">{{extremelyUnwilling}}</div>
      <div class="fair col">{{extremelyWilling}}</div>
    </div>
    <div class="score-label">{{solutionText}} 1</div>
    <div class="score-container row">
      <radio-button-score :text="1" :isSelected="solution1 === 1"
        @click="solution1 = 1"/>
      <radio-button-score :text="2" :isSelected="solution1 === 2"
        @click="solution1 = 2"/>
      <radio-button-score :text="3" :isSelected="solution1 === 3"
        @click="solution1 = 3"/>
      <radio-button-score :text="4" :isSelected="solution1 === 4"
        @click="solution1 = 4"/>
      <radio-button-score :text="5" :isSelected="solution1 === 5"
        @click="solution1 = 5"/>
      <radio-button-score :text="6" :isSelected="solution1 === 6"
        @click="solution1 = 6"/>
      <radio-button-score :text="7" :isSelected="solution1 === 7"
        @click="solution1 = 7"/>
      <radio-button-score :text="8" :isSelected="solution1 === 8"
        @click="solution1 = 8"/>
      <radio-button-score :text="9" :isSelected="solution1 === 9"
        @click="solution1 = 9"/>
    </div>
    <div class="score-label">{{solutionText}} 2</div>
    <div class="score-container row">
      <radio-button-score :text="1" :isSelected="solution2 === 1"
        @click="solution2 = 1"/>
      <radio-button-score :text="2" :isSelected="solution2 === 2"
        @click="solution2 = 2"/>
      <radio-button-score :text="3" :isSelected="solution2 === 3"
        @click="solution2 = 3"/>
      <radio-button-score :text="4" :isSelected="solution2 === 4"
        @click="solution2 = 4"/>
      <radio-button-score :text="5" :isSelected="solution2 === 5"
        @click="solution2 = 5"/>
      <radio-button-score :text="6" :isSelected="solution2 === 6"
        @click="solution2 = 6"/>
      <radio-button-score :text="7" :isSelected="solution2 === 7"
        @click="solution2 = 7"/>
      <radio-button-score :text="8" :isSelected="solution2 === 8"
        @click="solution2 = 8"/>
      <radio-button-score :text="9" :isSelected="solution2 === 9"
        @click="solution2 = 9"/>
    </div>
    <div class="score-label">{{solutionText}} 3</div>
    <div class="score-container row">
      <radio-button-score :text="1" :isSelected="solution3 === 1"
        @click="solution3 = 1"/>
      <radio-button-score :text="2" :isSelected="solution3 === 2"
        @click="solution3 = 2"/>
      <radio-button-score :text="3" :isSelected="solution3 === 3"
        @click="solution3 = 3"/>
      <radio-button-score :text="4" :isSelected="solution3 === 4"
        @click="solution3 = 4"/>
      <radio-button-score :text="5" :isSelected="solution3 === 5"
        @click="solution3 = 5"/>
      <radio-button-score :text="6" :isSelected="solution3 === 6"
        @click="solution3 = 6"/>
      <radio-button-score :text="7" :isSelected="solution3 === 7"
        @click="solution3 = 7"/>
      <radio-button-score :text="8" :isSelected="solution3 === 8"
        @click="solution3 = 8"/>
      <radio-button-score :text="9" :isSelected="solution3 === 9"
        @click="solution3 = 9"/>
    </div>
    <div class="continue-container">
      <continue-button :disabled="!canContinue" :text="continueText"
        @click="submit" >Continue</continue-button>
    </div>
  </div>
</template>

<script>
import Chart from '../../components/chart/chart.vue';
import RadioButtonScore from '../../components/radio-button/radio-button-score.vue';
import ContinueButton from '../../components/continue-button/continue-button.vue';

export default {
  components: {
    Chart,
    RadioButtonScore,
    ContinueButton,
  },
  data() {
    return {
      solution1: null,
      solution2: null,
      solution3: null,
    };
  },
  mounted() {
    this.scrollToTop();
  },
  computed: {
    translations() {
      return this.$store.state.translations;
    },
    solutionsSaw() {
      const def = 'Here are three solution you just saw:';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'solutionsSaw');
      return t ? t.value : def;
    },
    roadUsage() {
      const def = 'This is how much the road is used by different categories of people';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'roadUsage');
      return t ? t.value : def;
    },
    totalPersonHours() {
      const def = 'Total persons-hours (in millions)';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'totalPersonHours');
      return t ? t.value : def;
    },
    dieTitle() {
      const def = 'This is how many people die in traffic accidents each year';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'dieTitle');
      return t ? t.value : def;
    },
    totalFatalitiesText() {
      const def = 'Total fatalities';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'totalFatalitiesText');
      return t ? t.value : def;
    },
    imagineSave() {
      const def = 'Imagine we could save half of these people.';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'imagineSave');
      return t ? t.value : def;
    },
    whoToSave() {
      const def = 'Who should we save?';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'whoToSave');
      return t ? t.value : def;
    },
    solutionText() {
      const def = 'SOLUTION';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'solutionText');
      return t ? t.value : def;
    },
    yourOpinionText() {
      const def = 'Your Opinion';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'yourOpinionText');
      return t ? t.value : def;
    },
    willingToBuy() {
      const def = `How willing will you be to buy a self-driving
        car for each of these solutions?`;
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'willingToBuy');
      return t ? t.value : def;
    },
    extremelyUnwilling() {
      const def = '1 —EXTREMELY UNWILLING';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'extremelyUnwilling');
      return t ? t.value : def;
    },
    extremelyWilling() {
      const def = '9 — EXTREMELY WILLING';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'extremelyWilling');
      return t ? t.value : def;
    },
    continueText() {
      const def = 'Continue';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'continue');
      return t ? t.value : def;
    },
    canContinue() {
      if (this.solution1 && this.solution2 && this.solution3) {
        return true;
      }
      return false;
    },
    countryData() {
      return this.$store.state.currentCountry;
    },
    totalHours() {
      if (!this.countryData) {
        return 0;
      }
      return this.countryData.twoWheelerHours + this.countryData.cyclistsHours
        + this.countryData.pedestrianHours + this.countryData.carPassengersHours;
    },
    totalFatalities() {
      if (!this.countryData) {
        return 0;
      }
      return this.countryData.twoWheelerFatalities + this.countryData.cyclistsFatalities
        + this.countryData.carPassengerFatalities + this.countryData.pedestrianFatalities;
    },
    totalHoursNumber() {
      if (!this.countryData) {
        return 0;
      }
      return this.countryData.carPassengersHours + this.countryData.cyclistsHours
        + this.countryData.pedestrianHours + this.countryData.twoWheelerHours;
    },
    afterData1() {
      if (!this.countryData) {
        return null;
      }
      return {
        twoWheelers: Math.round(this.countryData.twoWheelerFatalities
          * this.fatalityReductionProportion),
        cyclists: Math.round(this.countryData.cyclistsFatalities
          * this.fatalityReductionProportion),
        pedestrians: Math.round(this.countryData.pedestrianFatalities
          * this.fatalityReductionProportion),
        carPassengers: Math.round(this.countryData.carPassengerFatalities
          * this.fatalityReductionProportion),
        saved: Math.round((this.countryData.twoWheelerFatalities
          + this.countryData.cyclistsFatalities
          + this.countryData.pedestrianFatalities
          + this.countryData.carPassengerFatalities) * this.fatalityReductionProportion),
      };
    },
    savedData() {
      if (!this.countryData && !this.afterData_1) {
        return null;
      }
      return {
        twoWheelers: this.countryData.twoWheelerFatalities - this.afterData_1.twoWheelers,
        cyclists: this.countryData.cyclistsFatalities - this.afterData_1.cyclists,
        pedestrians: this.countryData.pedestrianFatalities - this.afterData_1.pedestrians,
        carPassengers: this.countryData.carPassengerFatalities - this.afterData_1.carPassengers,
      };
    },
    twoWheelerRatio() {
      if (!this.countryData && !this.countryData.ratios) {
        return 0.033;
      }
      return this.countryData.ratios.twoWheelerRatio;
    },
    cyclistsRatio() {
      if (!this.countryData && !this.countryData.ratios) {
        return 0.034;
      }
      return this.countryData.ratios.cyclistsRatio;
    },
    pedestrianRatio() {
      if (!this.countryData && !this.countryData.ratios) {
        return 0.033;
      }
      return this.countryData.ratios.pedestrianRatio;
    },
    carRatio() {
      if (!this.countryData && !this.countryData.ratios) {
        return 0.9;
      }
      return this.countryData.ratios.carPassengersRatio;
    },
    appConfigData() {
      return this.$store.state.appConfigData;
    },
    fatalityReductionProportion() {
      if (!this.appConfigData) {
        return 0.5;
      }
      return this.appConfigData.fatalityReductionProportion;
    },
    carPassengerProportion() {
      if (!this.appConfigData) {
        return 0.9;
      }
      return this.appConfigData.carPassengerProportion;
    },
    othersProportion() {
      return (1 - this.carPassengerProportion) / 3;
    },
    afterData2() {
      if (!this.countryData) {
        return null;
      }
      return {
        twoWheelers: Math.round(this.countryData.twoWheelerFatalities
          - ((this.countryData.twoWheelerHours / this.totalHours)
          * (this.totalFatalities * this.fatalityReductionProportion))),
        cyclists: Math.round(this.countryData.cyclistsFatalities
          - ((this.countryData.cyclistsHours / this.totalHours)
          * (this.totalFatalities * this.fatalityReductionProportion))),
        pedestrians: Math.round(this.countryData.pedestrianFatalities
          - ((this.countryData.pedestrianHours / this.totalHours)
          * (this.totalFatalities * this.fatalityReductionProportion))),
        carPassengers: Math.round(this.countryData.carPassengerFatalities
          - ((this.countryData.carPassengersHours / this.totalHours)
          * (this.totalFatalities * this.fatalityReductionProportion))),
        saved: Math.round((this.countryData.twoWheelerFatalities
          + this.countryData.cyclistsFatalities
          + this.countryData.pedestrianFatalities
          + this.countryData.carPassengerFatalities) * this.fatalityReductionProportion),
      };
    },
    savedData2() {
      if (!this.countryData && !this.afterData_2) {
        return null;
      }
      return {
        twoWheelers: this.countryData.twoWheelerFatalities - this.afterData_2.twoWheelers,
        cyclists: this.countryData.cyclistsFatalities - this.afterData_2.cyclists,
        pedestrians: this.countryData.pedestrianFatalities - this.afterData_2.pedestrians,
        carPassengers: this.countryData.carPassengerFatalities - this.afterData_2.carPassengers,
      };
    },
    afterData3() {
      if (!this.countryData) {
        return null;
      }
      return {
        twoWheelers: Math.round((this.countryData.twoWheelerHours / this.totalHours)
          * (this.totalFatalities * this.fatalityReductionProportion)),
        cyclists: Math.round((this.countryData.cyclistsHours / this.totalHours)
          * (this.totalFatalities * this.fatalityReductionProportion)),
        pedestrians: Math.round((this.countryData.pedestrianHours / this.totalHours)
          * (this.totalFatalities * this.fatalityReductionProportion)),
        carPassengers: Math.round((this.countryData.carPassengersHours / this.totalHours)
          * (this.totalFatalities * this.fatalityReductionProportion)),
        saved: Math.round((this.countryData.twoWheelerFatalities
          + this.countryData.cyclistsFatalities
          + this.countryData.pedestrianFatalities
          + this.countryData.carPassengerFatalities) * this.fatalityReductionProportion),
      };
    },
    savedData3() {
      if (!this.countryData && !this.afterData_3) {
        return null;
      }
      return {
        twoWheelers: this.countryData.twoWheelerFatalities - this.afterData_3.twoWheelers,
        cyclists: this.countryData.cyclistsFatalities - this.afterData_3.cyclists,
        pedestrians: this.countryData.pedestrianFatalities - this.afterData_3.pedestrians,
        carPassengers: this.countryData.carPassengerFatalities - this.afterData_3.carPassengers,
      };
    },
    solution1Description() {
      const optionsArray = this.$store.state.staticSurveyOptions;
      const def = 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === `solution${optionsArray[0]}Description`);
      return t ? t.value : def;
    },
    solution2Description() {
      const optionsArray = this.$store.state.staticSurveyOptions;
      const def = 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === `solution${optionsArray[1]}Description`);
      return t ? t.value : def;
    },
    solution3Description() {
      const optionsArray = this.$store.state.staticSurveyOptions;
      const def = 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === `solution${optionsArray[2]}Description`);
      return t ? t.value : def;
    },
    totalFatalitiesHalf() {
      return Math.round(this.totalFatalities / 2);
    },
    afterData_1() {
      const optionsArray = this.$store.state.staticSurveyOptions;
      let data = {};
      if (optionsArray[0] === 1) {
        data = this.afterData1;
      } else if (optionsArray[0] === 2) {
        data = this.afterData2;
      } else {
        data = this.afterData3;
      }
      return data;
    },
    afterData_2() {
      const optionsArray = this.$store.state.staticSurveyOptions;
      let data = {};
      if (optionsArray[1] === 1) {
        data = this.afterData1;
      } else if (optionsArray[1] === 2) {
        data = this.afterData2;
      } else {
        data = this.afterData3;
      }
      return data;
    },
    afterData_3() {
      const optionsArray = this.$store.state.staticSurveyOptions;
      let data = {};
      if (optionsArray[2] === 1) {
        data = this.afterData1;
      } else if (optionsArray[2] === 2) {
        data = this.afterData2;
      } else {
        data = this.afterData3;
      }
      return data;
    },
  },
  methods: {
    submit() {
      // console.log(this.$store.state.userSessionInfo.userSessionId);
      this.$store.dispatch('submitSolutionPage', {
        userSessionId: this.$store.state.userSessionInfo.userSessionId,
        pageNumber: 4,
        willingnessToBuy1: this.solution1,
        willingnessToBuy2: this.solution2,
        willingnessToBuy3: this.solution3,
      });
      sessionStorage.setItem('staticSolutionPage', 1);
      if (sessionStorage.getItem('finalSectionCount')) {
        this.$router.push('/done');
      } else {
        this.$router.push('/final');
      }
    },
    scrollToTop() {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    },
  },
};
</script>

<style scoped>
.before-self-driving-container{
  margin-bottom: 20px;
}
.after-self-driving-container > h2,
.before-self-driving-container > h2 {
  font-family: 'Lora', serif;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 46px;
  color: #080D25;
}
.after-self-driving-container > h2 {
  margin-bottom: 40px;
}
.before-self-driving-container h4 {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #080D25;
}
.before-self-driving-img, .after-self-driving-img{
  padding: 0 40px;
}
.radio-input{
  margin-left: 14px;
}
.col-value{
  text-align: center;
}
.col-label{
  width: 164px !important;
  min-width: 164px !important;
  max-width: 164px !important;
}
.col-min-value{
  padding-right: 40px;
  min-width: 132px !important;
}
.col-max-value{
  padding-left: 40px;
  min-width: 132px !important;
}
.btn-right{
  float:right;
}
.hours-chart, .fatal-chart{
  margin: 44px 0px;
}
.page-container{
  margin-top: 84px;
}
@media only screen and (max-width: 700px) {
  .page-container{
    margin-top: 34px;
  }
}
.after-self-driving-container{
  margin-bottom: 50px;
  margin-top: 80px;
}
.solution-label{
  background: #080D25;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 16px;
  font-weight: 700;
  width: 124px;
  height: 27px;
  font-family: 'Lato', sans-serif;
  margin-top: 30px;
}
.solution-label-bottom{
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  margin-top: 8px;
  /* identical to box height */
  color: #080D25;
  font-family: 'Lato', sans-serif;
}
.understanding{
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 46px;
  font-family: 'Lora', serif;
  /* identical to box height */
  color: #080D25;
}
.please-read{
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  color: #57576B;
}

.please-read{
  margin-bottom: 40px;
}

.li-label{
  margin-bottom: 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  font-family: 'Lato', sans-serif;
  color: #080D25;
}

.ol-hr{
  margin: 36px 0px;
}

.radio-container > div {
  margin: 10px 0px;
}

.fairness{
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height */
  color: #080D25;
  margin-bottom: 24px;
}

.unfair{
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  color: #C11313;
}

.fair{
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  color: #079D16;
  text-align: end;
}

.fainrness-label{
  margin-bottom: 20px;
}

.score-label{
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #080D25;
}

.score-container{
  display: flex;
  flex-wrap: wrap;
  margin-top: 18px;
  padding-left: 18px;
}

.continue-container{
  display:flex;
  justify-content: center;
  align-content: center;
}
.solution-label-bottom{
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  margin-top: 8px;
  /* identical to box height */
  color: #080D25;
  font-family: 'Lato', sans-serif;
}
</style>
