import axios from 'axios';

const options = {
  baseURL: process.env.VUE_APP_API_BASE,
  mode: 'no-cors',
  crossDomain: true,
  headers: {
    'Access-Control-Allow-Origin': '*',
  },
};

const instance = axios.create(options);

export default instance;
