import baseApi from './baseApiService';

export default {
  async sendCountryData(formData) {
    try {
      return await baseApi.post('/admin/countryData',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
    } catch (error) {
      return error.response;
    }
  },
  async sendTranslationData(formData) {
    try {
      return await baseApi.post('/admin/translationsData',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
    } catch (error) {
      return error.response;
    }
  },
  async sendConfigData(data) {
    try {
      return await baseApi.post('/admin/appConfigData', data);
    } catch (error) {
      return error.response;
    }
  },
  async sendSingleTranslation(data) {
    try {
      return await baseApi.post('/admin/singleTranslation', data);
    } catch (error) {
      return error.response;
    }
  },
  async getStaticData(data) {
    try {
      return await baseApi.post('/admin/staticSurveys', data);
    } catch (error) {
      return error.response;
    }
  },
  async getInteractiveData(data) {
    try {
      return await baseApi.post('/admin/interactiveSurveys', data);
    } catch (error) {
      return error.response;
    }
  },
  async getAppConfigData() {
    const response = await baseApi.get('/admin/appconfigData');
    if (response.data && response.status === 200) {
      return response.data;
    }
    return null;
  },
  async getDemographicData(data) {
    try {
      return await baseApi.post('/admin/demographicSurveys', data);
    } catch (error) {
      return error.response;
    }
  },
  async emptyData(data) {
    try {
      return await baseApi.post('/admin/deleteData', data);
    } catch (error) {
      return error.response;
    }
  },
};
