import VueSocialSharing from 'vue-social-sharing';
import Vue from 'vue';
import Vuelidate from 'vuelidate';
import App from './App.vue';
import router from './router';
import store from './store';

Vue.use(Vuelidate);

Vue.config.productionTip = false;

Vue.filter('formatNumber', (value) => value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','));
Vue.use(VueSocialSharing);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
