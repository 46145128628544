import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Page1 from '../views/Pages/Page1.vue';
import Page2 from '../views/Pages/Page2.vue';
import Page3 from '../views/Pages/Page3.vue';
import SolutionPage from '../views/Pages/SolutionPage.vue';
import ConsentPage from '../views/Consent.vue';
import ConsentInteractivePage from '../views/ConsentInteractive.vue';
import IntroPage from '../views/Intro.vue';
import FinalPage from '../views/Final.vue';
import TransportPage from '../views/Info/transport.vue';
import UserInfoPage from '../views/Info/userInfo.vue';
import FeedbackPage from '../views/Info/feedback.vue';
import DonePage from '../views/Done.vue';
import AdminCountryData from '../views/Admin/adminCountryData.vue';
import AdminTranslationData from '../views/Admin/adminTranslationData.vue';
import AdminPropotionData from '../views/Admin/adminProportionData.vue';
import Landing from '../views/Landing.vue';
import Interactive1 from '../views/Interactive/Interactive1.vue';
import Interactive2 from '../views/Interactive/Interactive2.vue';
import Interactive3 from '../views/Interactive/Interactive3.vue';
import adminSingleTraslation from '../views/Admin/adminSingleTraslation.vue';
import adminExport from '../views/Admin/adminExport.vue';
import adminData from '../views/Admin/adminData.vue';
import Imprint from '../views/Imprint.vue';
import Policy from '../views/Policy.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Landing',
    component: Landing,
  },
  {
    path: '/static/:nextPage',
    name: 'Home',
    component: Home,
    props: true,
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  {
    path: '/page1',
    name: 'Page1',
    component: Page1,
  },
  {
    path: '/page2',
    name: 'Page2',
    component: Page2,
  },
  {
    path: '/page3',
    name: 'Page3',
    component: Page3,
  },
  {
    path: '/solution',
    name: 'Solution',
    component: SolutionPage,
  },
  {
    path: '/consent',
    name: 'Consent',
    component: ConsentPage,
  },
  {
    path: '/intro',
    name: 'Intro',
    component: IntroPage,
  },
  {
    path: '/final',
    name: 'Final',
    component: FinalPage,
  },
  {
    path: '/transport',
    name: 'Transport',
    component: TransportPage,
  },
  {
    path: '/userinfo',
    name: 'UserInfo',
    component: UserInfoPage,
  },
  {
    path: '/feedback',
    name: 'Feedback',
    component: FeedbackPage,
  },
  {
    path: '/done',
    name: 'Done',
    component: DonePage,
  },
  {
    path: '/countryDataAdmin',
    name: 'CountryDataAdmin',
    component: AdminCountryData,
  },
  {
    path: '/translationsAdmin',
    name: 'TranslationsAdmin',
    component: AdminTranslationData,
  },
  {
    path: '/proportionAdmin',
    name: 'ProportionsAdmin',
    component: AdminPropotionData,
  },
  {
    path: '/interactive1',
    name: 'Interactive1',
    component: Interactive1,
  },
  {
    path: '/interactive2',
    name: 'Interactive2',
    component: Interactive2,
  },
  {
    path: '/interactive3',
    name: 'Interactive3',
    component: Interactive3,
  },
  {
    path: '/consent-interactive',
    name: 'ConsentInteractive',
    component: ConsentInteractivePage,
  },
  {
    path: '/singleTranslationAdmin',
    name: 'SingleTranslationAdmin',
    component: adminSingleTraslation,
  },
  {
    path: '/adminExport',
    name: 'AdminExport',
    component: adminExport,
  },
  {
    path: '/adminData',
    name: 'AdminData',
    component: adminData,
  },
  {
    path: '/imprint',
    name: 'Imprint',
    component: Imprint,
  },
  {
    path: '/policy',
    name: 'Policy',
    component: Policy,
  },
];

const router = new VueRouter({
  routes,
});

export default router;
