<template>
  <div class="container">
    <div class="img-container">
      <img class="home-img" src="@/assets/homeImage.png" alt="home-image">
    </div>
    <div class="text-container">
      <h2 id="home_theStudy" v-if="theStudy !== '-'">{{theStudy}}</h2>
      <p id="home_aim" v-if="aimOfStudy !== '-'">{{aimOfStudy}}</p>
      <p id="home_participation" v-if="participation !== '-'">{{participation}}</p>
      <p id="home_who" v-if="who !== '-'">{{who}}</p>
      <p id="home_trafic" v-if="trafic !== '-'"><b>{{trafic}}</b></p>
      <p id="home_eliminate" v-if="eliminate !== '-'">{{eliminate}}</p>
      <p id="home_question" v-if="question !== '-'">{{question}}</p>
      <p id="home_example" v-if="example !== '-'">{{example}}</p>
      <p id="home_decisions" v-if="decisions !== '-'">{{decisions}}</p>
    </div>
    <div class="continue-container">
      <continue-button id="continue" :disabled="false" :text="contText"
        @click="next" >Continue</continue-button>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import ContinueButton from '../components/continue-button/continue-button.vue';

export default {
  name: 'Home',
  props: ['nextPage'],
  components: {
    ContinueButton,
  },
  mounted() {
    this.scrollToTop();
    console.log('Home mounted');
    console.log(this.nextPage);
  },
  computed: {
    translations() {
      return this.$store.state.translations;
    },
    theStudy() {
      if (!this.translations) {
        return 'The Study';
      }
      const t = this.translations.find((r) => r.key === 'home_theStudy');
      return t ? t.value : 'The Study';
    },
    aimOfStudy() {
      const def = `The aim of this study is to understand your intuitions about the possible impact of
        self-driving cars on road safety. We will show you some solutions,
        test your understanding, and ask about your opinions.
        The study will take approximately 5 minutes to complete, and you will receive 85 cents.`;
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'home_aim');
      return t ? t.value : def;
    },
    participation() {
      const def = `Your participation in this study is completely voluntary and you may refuse to participate
        or you may choose to withdraw at any time without penalty or loss of benefits
        to which you are otherwise entitled.`;
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'home_participation');
      return t ? t.value : def;
    },
    who() {
      const def = `This study is a research project of the Max Planck Institute (MPI) for Human Development.
        The data collected will be used for research purposes only.`;
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'home_who');
      return t ? t.value : def;
    },
    trafic() {
      const def = 'Traffic accidents in the world of self-driving cars';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'home_trafic');
      return t ? t.value : def;
    },
    eliminate() {
      const def = `Self-driving cars cannot eliminate all traffic accidents, but they can still decrease the
        number of casualties on the road. Fewer pedestrians will die, fewer bicyclists will die,
        fewer motorcyclists will die, and fewer car passengers will die.`;
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'home_eliminate');
      return t ? t.value : def;
    },
    question() {
      const def = `The question, though, is how much fewer people will die in each of these categories.
        The way we program the self-driving cars will decide which category of users will profit
        the most from the added safety of self-driving cars.`;
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'home_question');
      return t ? t.value : def;
    },
    example() {
      const def = `For example, we could program the cars to be especially protective of pedestrians,
        in which case much fewer pedestrians will die, but not so much fewer bicyclists,
        motorcyclists, or car passengers. But we could also make other decisions.`;
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'home_example');
      return t ? t.value : def;
    },
    decisions() {
      const def = 'We will show you three possible decisions, and ask how much you like them.';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'home_decisions');
      return t ? t.value : def;
    },
    contText() {
      const def = 'Continue';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'continue');
      return t ? t.value : def;
    },
  },
  methods: {
    next() {
      this.$router.push(`/${this.nextPage}`);
    },
    scrollToTop() {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    },
  },
};
</script>

<style scoped>
.home-img{
  width:100%;
}
.text-container {
  margin-top: 26px;
}
.text-container h2 {
  font-family: 'Lora', serif;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 46px;
  color: #080D25;
  margin-bottom: 16px;
}
.text-container p {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 130%;
}
.continue-container{
  display:flex;
  justify-content: center;
  align-content: center;
}
</style>
