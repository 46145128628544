<template>
  <div class="container consent-container">
    <h2 v-if="consentStatement !== '-'">{{consentStatement}}</h2>
    <p v-if="nature !== '-'">{{nature}}</p>
    <p v-if="please !== '-'">{{please}}</p>
    <hr>
    <div class="form-check">
      <input class="form-check-input" type="checkbox" v-model="consent" id="defaultCheck1">
      <label class="form-check-label" for="defaultCheck1">
        {{agree}}
      </label>
    </div>
    <div class="continue-container">
      <continue-button :disabled="!consent" :text="start"
        @click="next" :showImage="false"></continue-button>
    </div>
  </div>
</template>

<script>
import ContinueButton from '../components/continue-button/continue-button.vue';

export default {
  name: 'Consent',
  components: {
    ContinueButton,
  },
  data() {
    return {
      consent: false,
    };
  },
  computed: {
    translations() {
      return this.$store.state.translations;
    },
    consentStatement() {
      const def = 'Consent statement';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'consent_statement');
      return t ? t.value : def;
    },
    nature() {
      const def = `The nature and purpose of this research have been sufficiently explained
        and I agree to participate in this study. I understand that I am free to
        withdraw at any time.`;
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'consent_nature');
      return t ? t.value : def;
    },
    please() {
      const def = `Please consent by selecting the checkbox below. Otherwise,
        please exit the study at this time.`;
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'consent_please');
      return t ? t.value : def;
    },
    agree() {
      const def = 'I agree to participate in this study';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'consent_agree');
      return t ? t.value : def;
    },
    start() {
      const def = 'Start';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'start');
      return t ? t.value : def;
    },
  },
  mounted() {
    this.scrollToTop();
  },
  methods: {
    next() {
      const { userSessionId } = this.$store.state.userSessionInfo;
      this.$store.dispatch('initialSetupInteractive', { userSessionId });
      const optionsArray = this.$store.state.interactiveSurveyOptions;
      // console.log(optionsArray, 0);
      this.$router.push(`/interactive${optionsArray[0]}`);
    },
    scrollToTop() {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    },
  },
};
</script>

<style scoped>
.consent-container{
  margin-top: 80px;
}
.consent-container h2{
  font-family: 'Lora', serif;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  margin-bottom: 26px;
  line-height: 46px;
  color: #080D25;
}
.consent-container p{
  font-family: 'Lato', serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 133.5%;
  color: #080D25;
}
hr{
  margin: 36px 0px;
}
.continue-container{
  display:flex;
  justify-content: center;
  align-content: center;
}
</style>
