// import axios from 'axios';
import baseApi from './baseApiService';

export default {
  async getIpAddress() {
    const response = await baseApi.get('/user/getIpAddress');
    // console.log(response);
    return response.data.ip;
  },

  async getIpCode() {
    const response = await baseApi.get('/user/getIpAddress');
    return response.data.location.country.code;
  },
};
