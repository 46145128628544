<template>
  <div class="container mt-5">
    <h2 class="text-center">Translation Data Admin Page</h2>
    <p class="mt-5">
      Download template <a href="translationsTemplate.csv"
        download="translations.csv" class="btn btn-secondary ml-5">Donwload</a>
    </p>
    <div class="large-12 medium-12 small-12 cell">
      <label>File
        <input type="file" id="file" ref="file" v-on:change="handleFileUpload()"/>
      </label>
        <button v-on:click="submitFile()" class="btn btn-secondary">Submit</button>
    </div>
    <div class="large-12 medium-12 small-12 cell">
      <label>Admin key
        <input v-model="adminKey" type="password"/>
      </label>
    </div>
    <div>
      <p class="text-danger">{{errorMessage}}</p>
    </div>
    <div>
      <button @click="seeTranslations">See transaltions in console</button>
    </div>
  </div>
</template>

<script>
import adminApi from '../../api/admiApiService';

export default {
  data() {
    return {
      file: '',
      adminKey: '',
      errorMessage: '',
    };
  },
  computed: {
    translations() {
      return this.$store.state.translations;
    },
  },
  methods: {
    seeTranslations() {
      console.log(this.translations);
    },
    handleFileUpload() {
      // eslint-disable-next-line prefer-destructuring
      this.file = this.$refs.file.files[0];
    },
    async submitFile() {
      const formData = new FormData();
      formData.append('file', this.file);
      formData.append('adminKey', this.adminKey);
      const response = await adminApi.sendTranslationData(formData);
      if (response.status === 200) {
        this.$router.push('/');
      } else {
        this.errorMessage = response.data;
      }
    },
  },
};
</script>

<style>

</style>
