<template>
  <div class="container intro-container">
    <h2 id="intro_title" v-if="title !== '-'">{{title}}</h2>
    <p id="intro_before" v-if="before !== '-' && countryData != null">
      {{before}}
      <span>({{countryLabel}}: {{countryData.countryName}})</span>
    </p>
    <p id="intro_additional" v-if="additional !== '-'">{{additional}}
      <b id="intro_half">{{half}}</b></p>
    <p id="dataFrom" v-if="isRandom && countryData != null">
      {{dataFrom}} <b>{{countryData.countryName}}</b>.
    </p>
    <div class="continue-container">
      <continue-button id="continue" :disabled="false" :text="contText"
        @click="next"></continue-button>
    </div>
  </div>
</template>

<script>
import ContinueButton from '../components/continue-button/continue-button.vue';

export default {
  components: {
    ContinueButton,
  },
  mounted() {
    this.scrollToTop();
  },
  computed: {
    translations() {
      return this.$store.state.translations;
    },
    dataFrom() {
      const def = 'You are seeing the data for';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'dataFrom');
      return t ? t.value : def;
    },
    countryData() {
      return this.$store.state.currentCountry;
    },
    title() {
      const def = 'Introduction';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'intro_title');
      return t ? t.value : def;
    },
    before() {
      const def = `Before we begin, please note that the numbers we are about to
      show you come from a sample of few representative countries`;
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'intro_before');
      return t ? t.value : def;
    },
    additional() {
      const def = `Additionally, we assume that self-driving cars will reduce the
      number of road deaths `;
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'intro_additional');
      return t ? t.value : def;
    },
    half() {
      const def = 'by half.';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'intro_half');
      return t ? t.value : def;
    },
    contText() {
      const def = 'Continue';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'continue');
      return t ? t.value : def;
    },
    countryLabel() {
      const def = 'country';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'countryLabel');
      return t ? t.value : def;
    },
    isRandom() {
      if (this.countryData && this.countryData.isRandom) {
        return true;
      }
      return false;
    },
  },
  methods: {
    next() {
      const optionsArray = this.$store.state.staticSurveyOptions;
      // console.log(optionsArray, 0);
      this.$router.push(`/page${optionsArray[0]}`);
    },
    scrollToTop() {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    },
  },
};
</script>

<style scoped>
.intro-container {
  margin-top: 80px;
}

.intro-container h2{
  font-family: 'Lora', serif;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 46px;
  color: #080D25;
}

.intro-container p{
  font-family: 'Lato', serif;
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  line-height: 133.5%;
  color: #080D25;
  margin: 26px 0px;
}

.continue-container{
  display:flex;
  margin-top: 50px;
  justify-content: center;
  align-content: center;
}
</style>
