<template>
  <div class="container">
    <!-- <div class="title">{{dilema}}</div> -->
    <div class="question" v-if="programQuestion !== '-'">{{programQuestion}}</div>
    <div class="hint" v-if="dragText !== '-'">{{dragText}}</div>
    <interactive-3-desktop v-if="isDesktop" @score-change="changeScore"/>
    <interactive-3-mobile v-else @score-change="changeScore"/>
    <div class="results">
      <p class="label"><b>{{expectedNumber}}<span class="red">{{fatalities}}</span>
        {{perOneMillion}}</b></p>
      <div class="result-row">
        <div class="result-label">Car passenger:</div>
        <div class="result-icons">
          <div>
            {{enemyCarFatalities}}
            <img src="@/assets/InteractiveResults/car-red.png" alt="bike-fatality">
          </div>
        </div>
      </div>
      <div class="result-row">
        <div class="result-label">Self-driving car passenger:</div>
        <div class="result-icons">
          <div>
            {{carFatalities}}
            <img src="@/assets/InteractiveResults/car-red.png" alt="car-fatality">
          </div>
        </div>
      </div>
      <div class="result-row">
        <div class="result-label">Motorcyclist:</div>
        <div class="result-icons">
          <div>
            {{cyclistFatalities}}
            <img src="@/assets/InteractiveResults/motorcycle-red.svg" alt="truck-fatality">
          </div>
        </div>
      </div>
      <hr>
      <div class="totals">
        <b>
          <span class="total-fatalities">{{totalFatalitiesText}}:
            <span class="red total-number">{{cyclistFatalities +
              carFatalities + enemyCarFatalities}}</span>
          </span>
          <!-- <span class="total-injuries">{{totalInjuries}}:
            <span class="yellow total-number">{{cyclistInjuries +
              carInjuries + enemyCarInjuries}}</span>
          </span> -->
        </b>
      </div>
    </div>
    <div>
      <continue-button class="submit" :disabled="this.$store.state.continueBtn" :text="submit"
        @click="next" :showImage="false">{{submit}}</continue-button>
    </div>
  </div>
</template>

<script>
import highwayJsonData from '../../assets/statsFiles/HighwayExample.json';
import Interactive3Desktop from '../../components/interactive/interactive3Desktop.vue';
import Interactive3Mobile from '../../components/interactive/interactive3Mobile.vue';
import ContinueButton from '../../components/continue-button/continue-button.vue';

export default {
  components: {
    Interactive3Desktop,
    Interactive3Mobile,
    ContinueButton,
  },
  data() {
    return {
      score: {
        car: 0,
        cyclist: 0,
        enemyCar: 0,
      },
    };
  },
  computed: {
    translations() {
      return this.$store.state.translations;
    },
    dilema() {
      const def = 'Self-driving cars dilemma';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'dilema');
      return t ? t.value : def;
    },
    programQuestion() {
      const def = `Where would you program the autonomous
        vehicle to position itself it this situation?`;
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'programQuestion');
      return t ? t.value : def;
    },
    dragText() {
      const def = `Drag the green car or click on one of the
        arrows and check results below.`;
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'dragText');
      return t ? t.value : def;
    },
    expectedNumber() {
      const def = 'Expected number of ';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'expectedNumber');
      return t ? t.value : def;
    },
    fatalities() {
      const def = 'fatalities';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'fatalities');
      return t ? t.value : def;
    },
    and() {
      const def = 'and';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'and');
      return t ? t.value : def;
    },
    injuries() {
      const def = 'injuries';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'injuries');
      return t ? t.value : def;
    },
    perOneMillion() {
      const def = 'per 1 million cases';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'perOneMillion');
      return t ? t.value : def;
    },
    cyclistsText() {
      const def = 'Cyclists';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'cyclistsText');
      return t ? t.value : def;
    },
    carPassengersText() {
      const def = 'Car Passenger';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'carPassengersText');
      return t ? t.value : def;
    },
    truckDriver() {
      const def = 'Truck Driver';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'truckDriver');
      return t ? t.value : def;
    },
    totalFatalitiesText() {
      const def = 'Total fatalities';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'totalFatalitiesText');
      return t ? t.value : def;
    },
    totalInjuries() {
      const def = 'Total injuries';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'totalInjuries');
      return t ? t.value : def;
    },
    submit() {
      const def = 'Submit';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'submit');
      return t ? t.value : def;
    },
    isDesktop() {
      return window.innerWidth > 1000;
    },
    cyclistFatalities() {
      return this.score.cyclist;
    },
    cyclistInjuries() {
      return this.score.cyclist;
    },
    carFatalities() {
      return this.score.car;
    },
    carInjuries() {
      return this.score.car;
    },
    enemyCarFatalities() {
      return this.score.enemyCar;
    },
    enemyCarInjuries() {
      return this.score.enemyCar;
    },
  },
  mounted() {
    // this.$store.dispatch('initialSetupInteractive');
    this.scrollToTop();
    this.$store.state.stepsCount = 0;
    this.$store.state.continueBtn = false;
    // let surveyCount = localStorage.getItem('surveyCount');
    // localStorage.setItem('surveyCount', surveyCount += 1);
    this.highwayJsonData = highwayJsonData;
    this.changeScore();
  },
  methods: {
    getValuesFromCarPosition(step) {
      if (step >= 31) {
        // eslint-disable-next-line no-param-reassign
        step = 30;
      } else if (step <= -31) {
        // eslint-disable-next-line no-param-reassign
        step = -30;
      }
      const scoreData = this.highwayJsonData.filter((item) => item.Steps === step)[0];
      return [scoreData?.SportsCar, scoreData?.Motorcycle, scoreData?.AV];
    },
    changeScore() {
      let fatalities = [];
      console.log('steps', this.$store.state.stepsCount);
      if (this.$store.state.stepsCount >= 30 || this.$store.state.stepsCount <= -30) {
        this.$store.state.continueBtn = true;
        fatalities = ['', '', ''];
      } else {
        this.$store.state.continueBtn = false;
        fatalities = this.getValuesFromCarPosition(this.$store.state.stepsCount);
      }
      const [enemyCarFatalities, cyclistFatalities, carFatalities] = fatalities;
      this.score.car = carFatalities;
      this.score.cyclist = cyclistFatalities;
      this.score.enemyCar = enemyCarFatalities;
    },
    scrollToTop() {
      document.body.scrollTop = 80; // For Safari
      document.documentElement.scrollTop = 80; // For Chrome, Firefox, IE and Opera
    },
    getRandomInt(max) {
      return Math.floor(Math.random() * Math.floor(Math.abs(max % 5)));
    },
    async next() {
      await this.$store.dispatch('saveInteractive3', {
        userSessionId: this.$store.state.userSessionInfo.userSessionId,
        pageNumber: 3,
        chosenPosition: this.$store.state.stepsCount,
        fatalities_carPassenger: this.score.car,
        fatalities_selfDrivingCarPassenger: this.score.enemyCar,
        fatalities_motorCycle: this.score.cyclist,
      });
      const optionsArray = this.$store.state.interactiveSurveyOptions;
      const pageNumber = 3;
      let index = 0;
      for (let i = 0; i < 3; i += 1) {
        // console.log(optionsArray[i], pageNumber);
        if (pageNumber === optionsArray[i]) {
          index = i;
        }
      }
      // console.log(optionsArray, index);
      sessionStorage.setItem('interactivePageCount', index);
      if (index >= 2) {
        const finalSectionCount = sessionStorage.getItem('finalSectionCount');
        if (finalSectionCount === null || finalSectionCount < 0) {
          this.$router.push('/final');
        } else {
          this.$router.push('/done');
        }
      } else {
        index += 1;
        this.$router.push(`/interactive${optionsArray[index]}`);
      }
    },
  },
};
</script>

<style scoped>
.title{
  margin-top: 100px;
  font-family: Lora;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 46px;
  color: #080D25;
}
.question{
  margin-top: 5px;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #080D25;
}
.hint{
  margin-top: 24px;
  font-family: Lato;
  font-style: italic;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #1C35AC;
}
@media only screen and (max-width: 600px) {
  .title{
    margin-top: 38px;
  }
}

.results{
  margin-top: 50px;
  margin-bottom: 30px;
}

.red{
  color: #E50202;
}

.yellow{
  color: #E68C05;
}

.label {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
}

.total-fatalities{
  margin-right: 30px;
}

.totals{
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
}

.total-number{
  margin-left: 10px;
}

.result-label, .result-icons {
  display: inline-block;
}

.result-label{
  min-width: 120px;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #57576B;
}

.result-row {
  margin: 20px 0px;
  height: 40px;
}

.result-icons > div {
  display: inline-block;
  margin: 0px 5px;
}

.submit{
  margin: 0px auto;
  margin-bottom: 30px;
}
</style>
