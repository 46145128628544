<template>
  <div class="charts row">
    <div class="chart" :class="{'col-6': isWithSaved}">
      <div class="chart-header">
        <div class="chart-title" v-if="chartTitle !== '-'">{{chartTitle}}</div>
        <div class="chart-total">{{totalNumber | formatNumber}}</div>
      </div>
      <div ref="chartContainer" class="chart-container" :class="{'no-visibility': !chartLoaded}">
        <div class="bar-contaienr" :style="{width: twoWheelerBarPercentage+'%'}">
          <div ref="twoWheeler" class="two-wheeler-bar bar"> </div>
        </div>
        <div class="bar-contaienr" :style="{width: cyclistsBarPercentage+'%'}">
          <div ref="cyclistsBar" class="cyclists-bar bar"> </div>
        </div>
        <div class="bar-contaienr" :style="{width: pedestriansBarPercentage+'%'}">
          <div ref="pedestriansBar" class="pedestrians-bar bar"> </div>
        </div>
        <div class="bar-contaienr" :style="{width: carPassengersBarPercentage+'%'}">
          <div ref="carPassengersBar" class="car-passengers-bar bar"> </div>
        </div>
        <!-- <div v-if="saved" class="bar-contaienr" :style="{width: savedBarPerecentage+'%'}">
          <div ref="savedBar" class="saved-bar bar">
            {{savedPeopleText}}
          </div>
        </div> -->
      </div>
      <div class="info-container row">
        <div class="info info-two-wheelers col-md-3" :class="{'col-sm-12': isWithSaved,
          'col-sm-6': !isWithSaved, 'col-6': !isWithSaved}">
          <div class="info-title" v-if="twoWheelersText !== '-'">
            <span class="info-label">{{twoWheelersText}}</span>
          </div>
          <div class="info-content">
            <img class="icon" src="@/assets/surveyIcons/1.svg"/>
            <span class="info-percentage">{{twoWheelerPercentage}}</span>
            <span class="info-number">({{twoWheelers | formatNumber}})</span>
          </div>
        </div>
        <div class="info info-two-wheelers col-md-3" :class="{'col-sm-12': isWithSaved,
          'col-sm-6': !isWithSaved, 'col-6': !isWithSaved}">
          <div class="info-title">
            <span class="info-label" v-if="cyclistsText !== '-'">{{cyclistsText}}</span>
          </div>
          <div class="info-content">
            <img class="icon" src="@/assets/surveyIcons/2.svg"/>
            <span class="info-percentage">{{cyclistsPercentage}}</span>
            <span class="info-number">({{cyclists | formatNumber}})</span>
          </div>
        </div>
        <div class="info info-two-wheelers col-md-3" :class="{'col-sm-12': isWithSaved,
          'col-sm-6': !isWithSaved, 'col-6': !isWithSaved}">
          <div class="info-title">
            <span class="info-label" v-if="pedestrianText !== '-'">{{pedestrianText}}</span>
          </div>
          <div class="info-content">
            <img class="icon" src="@/assets/surveyIcons/3.svg"/>
            <span class="info-percentage">{{pedestriansPercentage}}</span>
            <span class="info-number">({{pedestrians | formatNumber}})</span>
          </div>
        </div>
        <div class="info info-two-wheelers col-md-3" :class="{'col-sm-12': isWithSaved,
          'col-sm-6': !isWithSaved, 'col-6': !isWithSaved}">
          <div class="info-title">
            <span class="info-label" v-if="carPassengersText !== '-'">{{carPassengersText}}</span>
          </div>
          <div class="info-content">
            <img class="icon" src="@/assets/surveyIcons/4.svg"/>
            <span class="info-percentage">{{carPassengersPercentage}}</span>
            <span class="info-number">({{carPassengers | formatNumber}})</span>
          </div>
        </div>
      </div>
    </div>
    <div class="chart col-6 saved-chart" v-if="isWithSaved">
      <div class="chart-header">
        <div class="chart-title">{{totalSavedPeople}}</div>
        <div class="chart-total">{{totalNumber | formatNumber}}</div>
      </div>
      <div ref="chartContainer" class="chart-container" :class="{'no-visibility': !chartLoaded}">
        <div class="bar-contaienr" :style="{width: twoWheelerBarPercentageSaved+'%'}">
          <div ref="twoWheeler" class="two-wheeler-bar bar"> </div>
        </div>
        <div class="bar-contaienr" :style="{width: cyclistsBarPercentageSaved+'%'}">
          <div ref="cyclistsBar" class="cyclists-bar bar"> </div>
        </div>
        <div class="bar-contaienr" :style="{width: pedestriansBarPercentageSaved+'%'}">
          <div ref="pedestriansBar" class="pedestrians-bar bar"> </div>
        </div>
        <div class="bar-contaienr" :style="{width: carPassengersBarPercentageSaved+'%'}">
          <div ref="carPassengersBar" class="car-passengers-bar bar"> </div>
        </div>
        <!-- <div v-if="saved" class="bar-contaienr" :style="{width: savedBarPerecentage+'%'}">
          <div ref="savedBar" class="saved-bar bar">
            {{savedPeopleText}}
          </div>
        </div> -->
      </div>
      <div class="info-container row">
        <div class="info info-two-wheelers col-md-3" :class="{'col-sm-12': isWithSaved,
          'col-sm-6': !isWithSaved}">
          <div class="info-title">
            <span class="info-label" v-if="twoWheelersText !== '-'">{{twoWheelersText}}</span>
          </div>
          <div class="info-content">
            <img class="icon" src="@/assets/surveyIcons/1.svg"/>
            <span class="info-percentage">{{twoWheelerPercentageSaved}}</span>
            <span class="info-number">({{savedData.twoWheelers | formatNumber}})</span>
          </div>
        </div>
        <div class="info info-two-wheelers col-md-3" :class="{'col-sm-12': isWithSaved,
          'col-sm-6': !isWithSaved}">
          <div class="info-title">
            <span class="info-label" v-if="cyclistsText !== '-'">{{cyclistsText}}</span>
          </div>
          <div class="info-content">
            <img class="icon" src="@/assets/surveyIcons/2.svg"/>
            <span class="info-percentage">{{cyclistsPercentageSaved}}</span>
            <span class="info-number">({{savedData.cyclists | formatNumber}})</span>
          </div>
        </div>
        <div class="info info-two-wheelers col-md-3" :class="{'col-sm-12': isWithSaved,
          'col-sm-6': !isWithSaved}">
          <div class="info-title">
            <span class="info-label" v-if="pedestrianText !== '-'">{{pedestrianText}}</span>
          </div>
          <div class="info-content">
            <img class="icon" src="@/assets/surveyIcons/3.svg"/>
            <span class="info-percentage">{{pedestriansPercentageSaved}}</span>
            <span class="info-number">({{savedData.pedestrians | formatNumber}})</span>
          </div>
        </div>
        <div class="info info-two-wheelers col-md-3" :class="{'col-sm-12': isWithSaved,
          'col-sm-6': !isWithSaved}">
          <div class="info-title">
            <span class="info-label" v-if="carPassengersText !== '-'">{{carPassengersText}}</span>
          </div>
          <div class="info-content">
            <img class="icon" src="@/assets/surveyIcons/4.svg"/>
            <span class="info-percentage">{{carPassengersPercentageSaved}}</span>
            <span class="info-number">({{savedData.carPassengers | formatNumber}})</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    twoWheelers: Number,
    cyclists: Number,
    pedestrians: Number,
    carPassengers: Number,
    chartTitle: String,
    saved: Number,
    savedData: Object,
    totalNumber: Number,
  },
  created() {
    if (this.twoWheelers <= 0) {
      this.twoWheelers = 0;
    }
    if (this.cyclists <= 0) {
      this.cyclists = 0;
    }
    if (this.pedestrians <= 0) {
      this.pedestrians = 0;
    }
    if (this.carPassengers <= 0) {
      this.carPassengers = 0;
    }
    if (this.saved <= 0) {
      this.saved = 0;
    }
  },
  data() {
    return {
      chartLoaded: false,
    };
  },
  computed: {
    translations() {
      return this.$store.state.translations;
    },
    isWithSaved() {
      return !!this.savedData;
    },
    totalSavedPeople() {
      const def = 'Total saved people';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'totalSavedPeople');
      return t ? t.value : def;
    },
    savedPeopleText() {
      const def = 'SAVED POEPLE';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'savedPeopleText');
      return t ? t.value : def;
    },
    twoWheelersText() {
      const def = 'Motorcyclists';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'twoWheelersText');
      return t ? t.value : def;
    },
    cyclistsText() {
      const def = 'Cyclists';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'cyclistsText');
      return t ? t.value : def;
    },
    pedestrianText() {
      const def = 'Pedestrians';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'pedestrianText');
      return t ? t.value : def;
    },
    carPassengersText() {
      const def = 'Car passengers';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'carPassengersText');
      return t ? t.value : def;
    },
    chartTotalFatalities() {
      return this.twoWheelers + this.cyclists + this.carPassengers + this.pedestrians;
    },
    chartTotalSave() {
      if (!this.isWithSaved) {
        return 0;
      }
      return this.chartTotalFatalities;
    },
    chartTotal() {
      if (this.saved) {
        return this.twoWheelers + this.cyclists + this.carPassengers + this.pedestrians;
      }
      return this.chartTotalFatalities;
    },
    twoWheelerPercentage() {
      const number = this.twoWheelers / this.chartTotalFatalities;
      return this.computePercentage(number);
    },
    twoWheelerPercentageSaved() {
      const number = this.savedData.twoWheelers / this.saved;
      return this.computePercentage(number);
    },
    cyclistsPercentage() {
      const number = this.cyclists / this.chartTotalFatalities;
      return this.computePercentage(number);
    },
    cyclistsPercentageSaved() {
      const number = this.savedData.cyclists / this.saved;
      return this.computePercentage(number);
    },
    pedestriansPercentage() {
      const number = this.pedestrians / this.chartTotalFatalities;
      return this.computePercentage(number);
    },
    pedestriansPercentageSaved() {
      const number = this.savedData.pedestrians / this.saved;
      return this.computePercentage(number);
    },
    carPassengersPercentage() {
      const number = this.carPassengers / this.chartTotalFatalities;
      return this.computePercentage(number);
    },
    carPassengersPercentageSaved() {
      const number = this.savedData.carPassengers / this.saved;
      return this.computePercentage(number);
    },
    twoWheelerBarPercentage() {
      const number = this.twoWheelers / this.chartTotal;
      return this.computeWidthPercentage(number);
    },
    twoWheelerBarPercentageSaved() {
      const number = this.savedData.twoWheelers / this.chartTotalSave;
      return this.computeWidthPercentage(number);
    },
    cyclistsBarPercentage() {
      const number = this.cyclists / this.chartTotal;
      return this.computeWidthPercentage(number);
    },
    cyclistsBarPercentageSaved() {
      const number = this.savedData.cyclists / this.chartTotalSave;
      return this.computeWidthPercentage(number);
    },
    pedestriansBarPercentage() {
      const number = this.pedestrians / this.chartTotal;
      return this.computeWidthPercentage(number);
    },
    pedestriansBarPercentageSaved() {
      const number = this.savedData.pedestrians / this.chartTotalSave;
      return this.computeWidthPercentage(number);
    },
    carPassengersBarPercentage() {
      const number = this.carPassengers / this.chartTotal;
      return this.computeWidthPercentage(number);
    },
    carPassengersBarPercentageSaved() {
      const number = this.savedData.carPassengers / this.chartTotalSave;
      return this.computeWidthPercentage(number);
    },
    savedBarPerecentage() {
      if (!this.saved || this.saved === 0) {
        return 0;
      }
      const number = this.saved / this.chartTotal;
      return this.computeWidthPercentage(number);
    },
  },
  methods: {
    computePercentage(n) {
      let number = n;
      if (number < 0.01) {
        return '<1%';
      }
      number *= 100;
      number = Math.round(number);
      return `${number}%`;
    },
    computeWidthPercentage(n) {
      let number = n;
      number *= 100;
      number = Math.round(number);
      return number;
    },
  },
  mounted() {
    this.chartLoaded = true;
  },
};
</script>

<style>
.chart{
  margin-top: 12px;
}
.chart-title{
  color: #57576b;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  font-family: 'Lato', sans-serif;
}
.info{
  font-family: 'Lato', sans-serif;
  white-space:nowrap;
}
.chart-total{
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #080D25;
  font-family: 'Lato', sans-serif;
}
.chart-container{
  width: 100%;
  display: flex;
  height: 40px;
  margin: 10px 0px;
}
.bullet{
  display: inline-block;
  content: " ";
  width: 8px;
  height: 8px;
  border-radius: 100%;
  margin-right: 8px;
  position: relative;
  top: -2px;
}
.info-two-wheelers .bullet {
  background-color: var(--two-wheeler-color);
}
.two-wheeler-bar{
  background-color: var(--two-wheeler-color);
}
.saved-chart .two-wheeler-bar {
  background-image: linear-gradient(135deg, var(--two-wheeler-color) 44.44%, #211433 44.44%,
  #211433 50%, var(--two-wheeler-color) 50%, var(--two-wheeler-color) 94.44%, #211433 94.44%,
  #211433 100%);
  background-size: 12.73px 12.73px;
}
.info-cyclists .bullet {
  background-color: var(--cyclists-color);
}
.cyclists-bar{
  background-color: var(--cyclists-color);
}
.saved-chart .cyclists-bar {
  background-image: linear-gradient(135deg, var(--cyclists-color) 44.44%, #211433 44.44%,
  #211433 50%, var(--cyclists-color) 50%, var(--cyclists-color) 94.44%, #211433 94.44%,
  #211433 100%);
  background-size: 12.73px 12.73px;
}
.info-pedestrians .bullet{
  background-color: var(--pedestrians-color);
}
.pedestrians-bar{
  background-color: var(--pedestrians-color);
}
.saved-chart .pedestrians-bar {
  background-image: linear-gradient(135deg, var(--pedestrians-color) 44.44%, #211433 44.44%,
  #211433 50%, var(--pedestrians-color) 50%, var(--pedestrians-color) 94.44%, #211433 94.44%,
  #211433 100%);
  background-size: 12.73px 12.73px;
}
.info-car-passengers .bullet {
  background-color: var(--car-passengers-color);
}
.car-passengers-bar{
  background-color: var(--car-passengers-color);
}
.saved-chart .car-passengers-bar {
  background-image: linear-gradient(135deg, var(--car-passengers-color) 44.44%, #211433 44.44%,
  #211433 50%, var(--car-passengers-color) 50%, var(--car-passengers-color) 94.44%, #211433 94.44%,
  #211433 100%);
  background-size: 12.73px 12.73px;
}
.info-label{
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #57576B;
}
.icon{
  width: 22px;
  height: 22px;
  margin-right: 6px;
}
.info-percentage{
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #080D25;
  margin-right: 6px;
}
.info-number{
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #57576B;
}
.bar-contaienr{
  box-sizing: border-box;
  width:25%;
  min-width: 8px;
}
.bar{
  margin: 0 1px;
  height: 100%;
  border-radius: 4px;
}
.no-visibility{
  visibility: hidden;
}
.saved-bar{
  border: 1px solid #AFAFBD;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  color: #57576B;
}
</style>
