<template>
  <div class="container userinfo-container">
    <p v-if="genderQuestion !== '-'">{{genderQuestion}}</p>
    <div class="options">
      <div class="row option-row">
        <radio-button :text="femaleText" :isSelected="gender === 1"
              @click="gender = 1"/>
      </div>
      <div class="row option-row">
        <radio-button :text="maleText" :isSelected="gender === 2"
              @click="gender = 2"/>
      </div>
      <div class="row option-row">
        <radio-button :text="otherText" :isSelected="gender === 3"
              @click="gender = 3"/>
      </div>
    </div>
    <hr>
    <p v-if="ageQuestion !== '-'">{{ageQuestion}}</p>
    <div class="form-group row">
      <input class="form-control input-age"
        :class="{'is-invalid': ageShowError }"
        type="number" v-model.number="ageLocal" min="0" step="1"
        pattern="[0-9]{10}"
        @change="age = ageLocal"
      >
      <div class="invalid-feedback">
        Age must be between 18-100
      </div>
    </div>
    <hr>
    <p v-if="politicalViewsQuestion !== '-'">{{politicalViewsQuestion}}</p>
    <div class="row fainrness-label">
      <div class="unfair col">{{conservativeText}}</div>
      <div class="fair col">{{progerssiveText}}</div>
    </div>
    <div class="political-container row">
      <radio-button-score :text="1" :isSelected="political === 1"
        @click="political = 1"/>
      <radio-button-score :text="2" :isSelected="political === 2"
        @click="political = 2"/>
      <radio-button-score :text="3" :isSelected="political === 3"
        @click="political = 3"/>
      <radio-button-score :text="4" :isSelected="political === 4"
        @click="political = 4"/>
      <radio-button-score :text="5" :isSelected="political === 5"
        @click="political = 5"/>
      <radio-button-score :text="6" :isSelected="political === 6"
        @click="political = 6"/>
      <radio-button-score :text="7" :isSelected="political === 7"
        @click="political = 7"/>
      <radio-button-score :text="8" :isSelected="political === 8"
        @click="political = 8"/>
      <radio-button-score :text="9" :isSelected="political === 9"
        @click="political = 9"/>
    </div>
    <hr>
    <p>{{religionQuestion}}</p>
    <div class="row fainrness-label">
      <div class="unfair col">{{notReligiousText}}</div>
      <div class="fair col">{{religiousText}}</div>
    </div>
    <div class="political-container row">
      <radio-button-score :text="1" :isSelected="religion === 1"
        @click="religion = 1"/>
      <radio-button-score :text="2" :isSelected="religion === 2"
        @click="religion = 2"/>
      <radio-button-score :text="3" :isSelected="religion === 3"
        @click="religion = 3"/>
      <radio-button-score :text="4" :isSelected="religion === 4"
        @click="religion = 4"/>
      <radio-button-score :text="5" :isSelected="religion === 5"
        @click="religion = 5"/>
      <radio-button-score :text="6" :isSelected="religion === 6"
        @click="religion = 6"/>
      <radio-button-score :text="7" :isSelected="religion === 7"
        @click="religion = 7"/>
      <radio-button-score :text="8" :isSelected="religion === 8"
        @click="religion = 8"/>
      <radio-button-score :text="9" :isSelected="religion === 9"
        @click="religion = 9"/>
    </div>
    <hr>
    <p v-if="educationQuestion !== '-'">{{educationQuestion}}</p>
    <div class="options">
      <div class="row option-row">
        <radio-button :text="lessThanHighSchool" :isSelected="education === 1"
              @click="education = 1"/>
      </div>
      <div class="row option-row">
        <radio-button :text="highSchool" :isSelected="education === 2"
              @click="education = 2"/>
      </div>
      <div class="row option-row">
        <radio-button :text="vocational" :isSelected="education === 3"
              @click="education = 3"/>
      </div>
      <div class="row option-row">
        <radio-button :text="attendedCollage" :isSelected="education === 4"
              @click="education = 4"/>
      </div>
      <div class="row option-row">
        <radio-button :text="bachelor" :isSelected="education === 5"
              @click="education = 5"/>
      </div>
      <div class="row option-row">
        <radio-button :text="graduate" :isSelected="education === 6"
              @click="education = 6"/>
      </div>
      <div class="row option-row">
        <radio-button :text="otherText" :isSelected="education === 7"
              @click="education = 7"/>
      </div>
    </div>
    <div class="continue-container">
      <continue-button :disabled="!canContinue" :text="continueText"
        @click="submit" ></continue-button>
    </div>
  </div>
</template>

<script>
import { between } from 'vuelidate/lib/validators';
import RadioButton from '../../components/radio-button/radio-button.vue';
import ContinueButton from '../../components/continue-button/continue-button.vue';
import RadioButtonScore from '../../components/radio-button/radio-button-score.vue';

export default {
  components: {
    RadioButton,
    ContinueButton,
    RadioButtonScore,
  },
  data() {
    return {
      ageLocal: null,
      gender: null,
      age: null,
      political: null,
      religion: null,
      education: null,
    };
  },
  validations: {
    age: {
      between: between(18, 100),
    },
  },
  mounted() {
    this.scrollToTop();
  },
  computed: {
    ageShowError() {
      return !this.$v.age.between && this.$v.age.$dirty;
    },
    translations() {
      return this.$store.state.translations;
    },
    genderQuestion() {
      const def = 'What is your gender?';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'genderQuestion');
      return t ? t.value : def;
    },
    femaleText() {
      const def = 'Female';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'femaleText');
      return t ? t.value : def;
    },
    maleText() {
      const def = 'Male';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'maleText');
      return t ? t.value : def;
    },
    otherText() {
      const def = 'Other';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'otherText');
      return t ? t.value : def;
    },
    ageQuestion() {
      const def = 'What is your age?';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'ageQuestion');
      return t ? t.value : def;
    },
    politicalViewsQuestion() {
      const def = 'What are your political views?';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'politicalViewsQuestion');
      return t ? t.value : def;
    },
    conservativeText() {
      const def = '1 — CONSERVATIVE';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'conservativeText');
      return t ? t.value : def;
    },
    progerssiveText() {
      const def = '9 — PROGRESSIVE';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'progerssiveText');
      return t ? t.value : def;
    },
    religionQuestion() {
      const def = 'What are your religious views?';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'religionQuestion');
      return t ? t.value : def;
    },
    notReligiousText() {
      const def = '1 — NOT RELIGIOUS';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'notReligiousText');
      return t ? t.value : def;
    },
    religiousText() {
      const def = '9 — VERY RELIGIOUS';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'religiousText');
      return t ? t.value : def;
    },
    educationQuestion() {
      const def = 'What is your highest level of education?';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'educationQuestion');
      return t ? t.value : def;
    },
    lessThanHighSchool() {
      const def = 'Less than a High School Diploma';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'lessThanHighSchool');
      return t ? t.value : def;
    },
    highSchool() {
      const def = 'High School Diploma';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'highSchool');
      return t ? t.value : def;
    },
    vocational() {
      const def = 'Vocational Training';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'vocational');
      return t ? t.value : def;
    },
    attendedCollage() {
      const def = 'Attended College';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'attendedCollage');
      return t ? t.value : def;
    },
    bachelor() {
      const def = 'Bachelor Degree';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'bachelor');
      return t ? t.value : def;
    },
    graduate() {
      const def = 'Graduate Degree';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'graduate');
      return t ? t.value : def;
    },
    continueText() {
      const def = 'Continue';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'continue');
      return t ? t.value : def;
    },
    canContinue() {
      if (this.gender && this.age && this.political
        && this.religion && this.education && !this.$v.$invalid) {
        return true;
      }
      return false;
    },
  },
  watch: {
    age() {
      this.$v.age.$touch();
    },
  },
  methods: {
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$store.dispatch('submitUserInfo', {
          userSessionId: this.$store.state.userSessionInfo.userSessionId,
          gender: this.gender,
          age: this.age,
          politicalViewScore: this.political,
          religiousViewScore: this.religion,
          highestEductionLevel: this.education,
          country: sessionStorage.getItem('countryCode'),
        });
        this.$router.push('feedback');
      }
    },
    scrollToTop() {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    },
  },
};
</script>

<style scoped>
.userinfo-container {
  margin-top: 60px;
}

.userinfo-container p{
  font-family: 'Lato', serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  color: #080D25;
  margin-bottom: 30px;
}

.option-row{
  margin: 16px 0px;
}

.continue-container{
  display:flex;
  justify-content: center;
  align-content: center;
  margin-top: 40px;
}

hr{
  margin: 36px 0px;
}

.input-age{
  width: 120px;
  margin-left: 12px;
}
.fainrness-label{
  margin-bottom: 20px;
}

.unfair{
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
}

.fair{
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  text-align: end;
}
</style>
