<template>
  <div class="container transport-container">
    <p v-if="whichTransport !== '-'">{{whichTransport}}</p>
    <div class="options">
      <div class="row option-row">
        <radio-button :text="cyclingText" :isSelected="option === 1"
              @click="option = 1"/>
      </div>
      <div class="row option-row">
        <radio-button :text="walkingText" :isSelected="option === 2"
              @click="option = 2"/>
      </div>
      <div class="row option-row">
        <radio-button :text="carsText" :isSelected="option === 3"
              @click="option = 3"/>
      </div>
      <div class="row option-row">
        <radio-button :text="twoWheelerText" :isSelected="option === 4"
              @click="option = 4"/>
      </div>
    </div>
    <div class="continue-container">
      <continue-button :disabled="!canContinue" :text="continueText"
        @click="submit" ></continue-button>
    </div>
  </div>
</template>

<script>
import RadioButton from '../../components/radio-button/radio-button.vue';
import ContinueButton from '../../components/continue-button/continue-button.vue';

export default {
  components: {
    RadioButton,
    ContinueButton,
  },
  data() {
    return {
      option: null,
    };
  },
  computed: {
    translations() {
      return this.$store.state.translations;
    },
    whichTransport() {
      const def = 'Which mode of transport you personally use the most often?';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'whichTransport');
      return t ? t.value : def;
    },
    cyclingText() {
      const def = 'Cycling';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'cyclingText');
      return t ? t.value : def;
    },
    walkingText() {
      const def = 'Walking';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'walkingText');
      return t ? t.value : def;
    },
    carsText() {
      const def = 'Passengers cars, buses, trucks, and SUVs';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'carsText');
      return t ? t.value : def;
    },
    twoWheelerText() {
      const def = 'Motorcyclists';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'twoWheelerText');
      return t ? t.value : def;
    },
    continueText() {
      const def = 'Continue';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'continue');
      return t ? t.value : def;
    },
    canContinue() {
      if (this.option) {
        return true;
      }
      return false;
    },
  },
  methods: {
    submit() {
      this.$store.dispatch('submitUserTransport', {
        userSessionId: this.$store.state.userSessionInfo.userSessionId,
        mostOftenTransportUsed: this.option,
      });
      this.$router.push('userinfo');
    },
  },
};
</script>

<style scoped>
.transport-container{
  margin-top: 60px;
}

.transport-container p{
  font-family: 'Lato', serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  color: #080D25;
  margin-bottom: 44px;
}

.option-row{
  margin: 16px 0px;
}

.continue-container{
  display:flex;
  justify-content: center;
  align-content: center;
  margin-top: 40px;
}
</style>
