<template>
  <div class="container landing-container">
    <div class="row justify-content-between">
      <div id="landingStudy" class="col-10 col-md-7 title" v-if="landingStudy !== '-'">
        {{landingStudy}}
      </div>
      <div class="col-2 shares-container float-right">
        <div id="landingShare" class="share-label" v-if="landingShare !== '-'">
          {{landingShare}}:
        </div>
        <div class="shares">
          <ShareNetwork
            :network="twitter.network"
            :url="sharing.url"
            :style="{position: 'relative', top: '3px', marginRight: '6px'}"
            :title="sharing.title"
            :description="sharing.description"
            :quote="sharing.quote"
            :hashtags="sharing.hashtags"
            :twitterUser="sharing.twitterUser"
          >
            <img src="@/assets/Twitter.png" alt="twitter">
          </ShareNetwork>
          <ShareNetwork
            :network="facebook.network"
            :url="sharing.url"
            :style="{marginRight: '8px'}"
            :title="sharing.title"
            :description="sharing.description"
            :quote="sharing.quote"
            :hashtags="sharing.hashtags"
            :twitterUser="sharing.twitterUser"
          >
            <img src="@/assets/facebook.png" alt="twitter">
          </ShareNetwork>
          <a href="mailto:inderonline1988@gmail.com">
            <img src="@/assets/mail.fill_24px.png" alt="mail">
          </a>
        </div>
      </div>
    </div>
    <div>
      <p id="landingDescription" class="landingDescription"
        v-if="landingDescription !== '-'">{{landingDescription}}</p>
    </div>
    <div class="static-version-container">
      <div class="static-icons">
        <img class="static-icon" src="@/assets/surveyIcons/1.svg" alt="motorbike">
        <img class="static-icon" src="@/assets/surveyIcons/2.svg" alt="bike">
        <img class="static-icon" src="@/assets/surveyIcons/3.svg" alt="pedestrian">
        <img class="static-icon" src="@/assets/surveyIcons/4.svg" alt="car">
      </div>
      <div id="landingStaticSurvey" class="static-survey-title" v-if="landingStaticSurvey !== '-'">
        {{landingStaticSurvey}}
      </div>
      <div id="staticDescription" class="static-description" v-if="staticDescription !== '-'">
        {{staticDescription}}
      </div>
      <div id="staticEstimated" class="static-estimated-time" v-if="staticEstimated !== '-'">
        <img src="@/assets/schedule_24px.png" alt="watch" class="watch-icon">
        {{staticEstimated}}
      </div>
      <div class="static-button">
        <continue-button id="staticButtonText" :disabled="false" :text="staticButtonText"
          @click="goToStatic" :showImage="false"></continue-button>
      </div>
    </div>
    <div class="interacti-version-container">
      <div class="car-image">
        <img src="@/assets/landing-car.png" alt="car">
      </div>
      <div class="interactive-version-sub-container">
        <div id="landingInteractiveSurvey" class="interactive-survey-title"
          v-if="landingInteractiveSurvey !== '-'">
          {{landingInteractiveSurvey}}
        </div>
        <div id="interactiveDescription" class="static-description"
          v-if="interactiveDescription !== '-'">
          {{interactiveDescription}}
        </div>
        <div id="interactiveEstimated" class="static-estimated-time"
          v-if="interactiveEstimated !== '-'">
          <img src="@/assets/schedule_24px.png" alt="watch" class="watch-icon">
          {{interactiveEstimated}}
        </div>
        <div class="static-button">
          <continue-button id="interactiveButtonText" :disabled="false"
            :text="interactiveButtonText"
            @click="goToInteractive" :showImage="false"></continue-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContinueButton from '../components/continue-button/continue-button.vue';

export default {
  components: {
    ContinueButton,
  },
  data() {
    return {
      /*eslint-disable */
      networks: [
        { network: 'facebook', name: 'Facebook', icon: 'fab fah fa-lg fa-facebook-f', color: '#1877f2' },
        { network: 'twitter', name: 'Twitter', icon: 'fab fah fa-lg fa-twitter', color: '#1da1f2' },
      ],
      sharing: {
        url: process.env.VUE_APP_DOMAIN_LINK,
        title: 'Complete this self driving car survery',
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s',
        quote: 'The cars we drive say a lot about us.',
        hashtags: 'selfDrivingCars',
      },
      /* eslint-enable */
    };
  },
  mounted() {
    // localStorage.removeItem('interactiveSurveyId');
    this.$store.dispatch('resetState');
    this.$store.dispatch('initialSetup');
  },
  computed: {
    facebook() {
      return this.networks[0];
    },
    twitter() {
      return this.networks[1];
    },
    translations() {
      return this.$store.state.translations;
    },
    landingStudy() {
      const def = 'The self-driving cars safety study';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'landingStudy');
      return t ? t.value : def;
    },
    landingShare() {
      const def = 'Share';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'landingShare');
      return t ? t.value : def;
    },
    landingDescription() {
      const def = 'In this study, we wish to understand your opinions about the possible impact of self-driving cars on road safety.';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'landingDescription');
      return t ? t.value : def;
    },
    landingStaticSurvey() {
      const def = 'Static Survey';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'landingStaticSurvey');
      return t ? t.value : def;
    },
    staticDescription() {
      const def = 'In this survey, you will see our possible solutions to road safety issues, understand them, and answer a few questions. ';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'staticDescription');
      return t ? t.value : def;
    },
    staticEstimated() {
      const def = 'Estimated Time: 20 min';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'staticEstimated');
      return t ? t.value : def;
    },
    staticButtonText() {
      const def = 'Start Static Survey';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'staticButtonText');
      return t ? t.value : def;
    },
    landingInteractiveSurvey() {
      const def = 'Interactive Survey';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'landingInteractiveSurvey');
      return t ? t.value : def;
    },
    interactiveDescription() {
      const def = 'In this survey, you can create your own solutions to road safety issues. ';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'interactiveDescription');
      return t ? t.value : def;
    },
    interactiveEstimated() {
      const def = 'Estimated Time: 10 min';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'interactiveEstimated');
      return t ? t.value : def;
    },
    interactiveButtonText() {
      const def = 'Start Interactive Survey';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'interactiveButtonText');
      return t ? t.value : def;
    },
  },
  methods: {
    goToStatic() {
      const staticPagesCount = sessionStorage.getItem('staticPageCount');
      const staticSolutionPage = sessionStorage.getItem('staticSolutionPage');
      if (staticPagesCount === '2' && staticSolutionPage === '1') {
        this.$router.push('/done');
      } else {
        this.$router.push('/static/consent');
      }
    },
    goToInteractive() {
      const interactivePageCount = sessionStorage.getItem('interactivePageCount');
      if (interactivePageCount === '2') {
        this.$router.push('/done');
      } else {
        this.$router.push('/static/consent-interactive');
      }
    },
  },
};
</script>

<style scoped>
.landing-container{
  padding-top: 80px;
}
.title{
  font-family: Lora;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 61px;
  color: #080D25;
}
.shares-container{
  margin-top: 20px;
}

.share-label{
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #57576B;
  margin-bottom: 16px;;
}
.landingDescription{
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 130%;
  color: #080D25;
  margin-top: 32px;
}
.static-version-container{
  margin-top: 111px;
  padding-left: 78px;
}
.static-icon{
  margin-right: 10px;
}
.static-survey-title{
  font-family: Lora;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 46px;
  color: #080D25;
  margin-top: 13px;
}
.interactive-survey-title{
  font-family: Lora;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 46px;
  color: #080D25;
}
.static-description{
  max-width: 550px;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  color: #080D25;
  margin-top: 30px;
}
.static-estimated-time{
  margin-top: 26px;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #57576B;
}
.watch-icon{
  margin-right: 6px;
}
.interacti-version-container{
  margin-left: 350px;
  display: flex;
  padding-bottom: 300px;
}
.car-image img{
  width: 83px;
  height: 162.94px;
}
.interactive-version-sub-container{
  margin-left: 30px;
}

@media only screen and (max-width: 600px) {
  .interacti-version-container{
    margin-left: 0px;
  }
  .static-version-container{
    padding-left: 0px;
  }
}
</style>
