<template>
  <div class="container page-container">
    <h1 v-if="solutionText !== '-'" id="solutionText" class="text-center">
      {{solutionText}} {{solutionNumber}}
    </h1>
    <div class="before-self-driving-container">
      <h2 v-if="beforeSelfDriving !== '-'">{{beforeSelfDriving}}</h2>
      <div class="hours-chart">
        <h4 v-if="roadUsage !== '-'">{{roadUsage}}</h4>
        <chart v-if="countryData" :chartTitle="totalPersonHours"
          :twoWheelers="countryData.twoWheelerHours" :cyclists="countryData.cyclistsHours"
            :pedestrians="countryData.pedestrianHours"
            :carPassengers="countryData.carPassengersHours"
            :totalNumber="totalHoursNumber"/>
      </div>
      <hr>
      <div class="fatal-chart">
        <h4 v-if="dieTitle !== '-'">{{dieTitle}}</h4>
        <chart v-if="countryData" :chartTitle="totalFatalitiesText"
          :twoWheelers="countryData.twoWheelerFatalities"
          :cyclists="countryData.cyclistsFatalities"
          :pedestrians="countryData.pedestrianFatalities"
          :carPassengers="countryData.carPassengerFatalities"
          :totalNumber="totalFatalities"/>
      </div>
    </div>
    <div class="after-self-driving-container">
      <h2 v-if="afterSelfDriving !== '-'">{{afterSelfDriving}}</h2>
      <!-- <div class="solution-label" v-if="solutionText !== '-'">{{solutionText}}
        {{solutionNumber}}</div> -->
      <div class="solution-label-bottom" v-if="solution2Description !== '-'">
        {{solution2Description}}
      </div>
      <chart v-if="afterData" :twoWheelers="afterData.twoWheelers" :cyclists="afterData.cyclists"
        :pedestrians="afterData.pedestrians"
        :carPassengers="afterData.carPassengers"
        :saved="afterData.saved"
        :savedData="savedData"
        :chartTitle="totalFatalitiesText"
        :totalNumber="totalFatalitiesHalf"/>
    </div>
    <div class="understanding" v-if="understandingTest !== '-'">{{understandingTest}}</div>
    <div class="please-read" v-if="pleaseReadAndAnswer !== '-'">{{pleaseReadAndAnswer}}</div>
    <ol>
      <li>
        <div class="li-label" v-if="solution2Question1 !== '-'">
          {{solution2Question1}}
        </div>
        <div class="row radio-container">
          <div class="col-sm-4 col-12">
            <radio-button :text="theSameAsBefore" :isSelected="newNumberOfPedestrians === 1"
              @click="newNumberOfPedestrians = 1"/>
          </div>
          <div class="col-sm-4 col-12">
            <radio-button :text="moreThanBefore" :isSelected="newNumberOfPedestrians === 3"
              @click="newNumberOfPedestrians = 3"/>
          </div>
          <div class="col-sm-4 col-12">
            <radio-button :text="lessThanBefore" :isSelected="newNumberOfPedestrians === 2"
              @click="newNumberOfPedestrians = 2"/>
          </div>
        </div>
      </li>
      <hr class="ol-hr">
      <li>
        <div class="li-label" v-if="solution2Question2 !== '-'">{{solution2Question2}}</div>
        <div class="row radio-container">
          <div class="col-sm-4 col-12">
            <radio-button :text="unclear" :isSelected="numberOfFatalitiesReduced === 1"
              @click="numberOfFatalitiesReduced = 1"/>
          </div>
          <div class="col-sm-4 col-12">
            <radio-button :text="yesText" :isSelected="numberOfFatalitiesReduced === 3"
              @click="numberOfFatalitiesReduced = 3"/>
          </div>
          <div class="col-sm-4 col-12">
            <radio-button :text="noText" :isSelected="numberOfFatalitiesReduced === 2"
              @click="numberOfFatalitiesReduced = 2"/>
          </div>
        </div>
      </li>
      <hr class="ol-hr">
      <li>
        <div class="li-label" v-if="solution2Question3 !== '-'">{{solution2Question3}}</div>
        <div class="row radio-container">
          <div class="col-sm-4 col-12">
            <radio-button id="carPassengersText"
              :text="cyclistsText" :isSelected="usesTheRoadTheLeast === 1"
              @click="usesTheRoadTheLeast = 1"/>
          </div>
          <div class="col-sm-4 col-12">
            <radio-button :text="pedestrianText" :isSelected="usesTheRoadTheLeast === 2"
              @click="usesTheRoadTheLeast = 2"/>
          </div>
          <div class="col-sm-4 col-12">
            <radio-button :text="motorcyclistsText" :isSelected="usesTheRoadTheLeast === 3"
              @click="usesTheRoadTheLeast = 3"/>
          </div>
        </div>
      </li>
    </ol>
    <div class="understanding" v-if="yourOpinionText !== '-'">{{yourOpinionText}}</div>
    <div class="please-read" v-if="noteRightWrong !== '-'">{{noteRightWrong}}</div>
    <div class="fairness" v-if="howFairText !== '-'">{{howFairText}}</div>
    <div class="row fainrness-label">
      <div class="unfair col">{{unfairLabelText}}</div>
      <div class="fair col">{{fairLabelText}}</div>
    </div>
    <div id="twoWheelersText" class="score-label">
      {{twoWheelersText}}
      <img class="icon" src="@/assets/surveyIcons/1.svg"/>
    </div>
    <div class="score-container row">
      <radio-button-score :text="1" :isSelected="twoWheelersScore === 1"
        @click="twoWheelersScore = 1"/>
      <radio-button-score :text="2" :isSelected="twoWheelersScore === 2"
        @click="twoWheelersScore = 2"/>
      <radio-button-score :text="3" :isSelected="twoWheelersScore === 3"
        @click="twoWheelersScore = 3"/>
      <radio-button-score :text="4" :isSelected="twoWheelersScore === 4"
        @click="twoWheelersScore = 4"/>
      <radio-button-score :text="5" :isSelected="twoWheelersScore === 5"
        @click="twoWheelersScore = 5"/>
      <radio-button-score :text="6" :isSelected="twoWheelersScore === 6"
        @click="twoWheelersScore = 6"/>
      <radio-button-score :text="7" :isSelected="twoWheelersScore === 7"
        @click="twoWheelersScore = 7"/>
      <radio-button-score :text="8" :isSelected="twoWheelersScore === 8"
        @click="twoWheelersScore = 8"/>
      <radio-button-score :text="9" :isSelected="twoWheelersScore === 9"
        @click="twoWheelersScore = 9"/>
    </div>
    <div id="cyclistsText" class="score-label">
      {{cyclistsText}}
      <img class="icon" src="@/assets/surveyIcons/2.svg"/>
    </div>
    <div class="score-container row">
      <radio-button-score :text="1" :isSelected="cyclistsScore === 1"
        @click="cyclistsScore = 1"/>
      <radio-button-score :text="2" :isSelected="cyclistsScore === 2"
        @click="cyclistsScore = 2"/>
      <radio-button-score :text="3" :isSelected="cyclistsScore === 3"
        @click="cyclistsScore = 3"/>
      <radio-button-score :text="4" :isSelected="cyclistsScore === 4"
        @click="cyclistsScore = 4"/>
      <radio-button-score :text="5" :isSelected="cyclistsScore === 5"
        @click="cyclistsScore = 5"/>
      <radio-button-score :text="6" :isSelected="cyclistsScore === 6"
        @click="cyclistsScore = 6"/>
      <radio-button-score :text="7" :isSelected="cyclistsScore === 7"
        @click="cyclistsScore = 7"/>
      <radio-button-score :text="8" :isSelected="cyclistsScore === 8"
        @click="cyclistsScore = 8"/>
      <radio-button-score :text="9" :isSelected="cyclistsScore === 9"
        @click="cyclistsScore = 9"/>
    </div>
    <div id="pedestrianText" class="score-label">
      {{pedestrianText}}
      <img class="icon" src="@/assets/surveyIcons/3.svg"/>
    </div>
    <div class="score-container row">
      <radio-button-score :text="1" :isSelected="pedestriansScore === 1"
        @click="pedestriansScore = 1"/>
      <radio-button-score :text="2" :isSelected="pedestriansScore === 2"
        @click="pedestriansScore = 2"/>
      <radio-button-score :text="3" :isSelected="pedestriansScore === 3"
        @click="pedestriansScore = 3"/>
      <radio-button-score :text="4" :isSelected="pedestriansScore === 4"
        @click="pedestriansScore = 4"/>
      <radio-button-score :text="5" :isSelected="pedestriansScore === 5"
        @click="pedestriansScore = 5"/>
      <radio-button-score :text="6" :isSelected="pedestriansScore === 6"
        @click="pedestriansScore = 6"/>
      <radio-button-score :text="7" :isSelected="pedestriansScore === 7"
        @click="pedestriansScore = 7"/>
      <radio-button-score :text="8" :isSelected="pedestriansScore === 8"
        @click="pedestriansScore = 8"/>
      <radio-button-score :text="9" :isSelected="pedestriansScore === 9"
        @click="pedestriansScore = 9"/>
    </div>
    <div class="score-label">
      {{carPassengersText}}
      <img class="icon" src="@/assets/surveyIcons/4.svg"/>
    </div>
    <div class="score-container row">
      <radio-button-score :text="1" :isSelected="carPassengersScore === 1"
        @click="carPassengersScore = 1"/>
      <radio-button-score :text="2" :isSelected="carPassengersScore === 2"
        @click="carPassengersScore = 2"/>
      <radio-button-score :text="3" :isSelected="carPassengersScore === 3"
        @click="carPassengersScore = 3"/>
      <radio-button-score :text="4" :isSelected="carPassengersScore === 4"
        @click="carPassengersScore = 4"/>
      <radio-button-score :text="5" :isSelected="carPassengersScore === 5"
        @click="carPassengersScore = 5"/>
      <radio-button-score :text="6" :isSelected="carPassengersScore === 6"
        @click="carPassengersScore = 6"/>
      <radio-button-score :text="7" :isSelected="carPassengersScore === 7"
        @click="carPassengersScore = 7"/>
      <radio-button-score :text="8" :isSelected="carPassengersScore === 8"
        @click="carPassengersScore = 8"/>
      <radio-button-score :text="9" :isSelected="carPassengersScore === 9"
        @click="carPassengersScore = 9"/>
    </div>
    <div class="continue-container">
      <continue-button :disabled="!canContinue" :text="continueText"
        @click="submit" >Continue</continue-button>
    </div>
  </div>
</template>

<script>
import Chart from '../../components/chart/chart.vue';
import RadioButton from '../../components/radio-button/radio-button.vue';
import RadioButtonScore from '../../components/radio-button/radio-button-score.vue';
import ContinueButton from '../../components/continue-button/continue-button.vue';

export default {
  components: {
    Chart,
    RadioButton,
    RadioButtonScore,
    ContinueButton,
  },
  data() {
    return {
      newNumberOfPedestrians: null,
      numberOfFatalitiesReduced: null,
      usesTheRoadTheLeast: null,
      carPassengersScore: null,
      pedestriansScore: null,
      cyclistsScore: null,
      twoWheelersScore: null,
    };
  },
  mounted() {
    this.scrollToTop();
  },
  computed: {
    totalHoursNumber() {
      if (!this.countryData) {
        return 0;
      }
      return this.countryData.carPassengersHours + this.countryData.cyclistsHours
        + this.countryData.pedestrianHours + this.countryData.twoWheelerHours;
    },
    totalFatalities() {
      if (!this.countryData) {
        return 0;
      }
      return this.countryData.twoWheelerFatalities + this.countryData.cyclistsFatalities
        + this.countryData.carPassengerFatalities + this.countryData.pedestrianFatalities;
    },
    totalFatalitiesHalf() {
      return Math.round(this.totalFatalities / 2);
    },
    totalHours() {
      if (!this.countryData) {
        return 0;
      }
      return this.countryData.twoWheelerHours + this.countryData.cyclistsHours
        + this.countryData.pedestrianHours + this.countryData.carPassengersHours;
    },
    translations() {
      return this.$store.state.translations;
    },
    beforeSelfDriving() {
      const def = 'Before self-driving cars';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'beforeSelfDriving');
      return t ? t.value : def;
    },
    roadUsage() {
      const def = 'This is how much the road is used by different categories of people';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'roadUsage');
      return t ? t.value : def;
    },
    totalPersonHours() {
      const def = 'Total persons-hours (in millions)';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'totalPersonHours');
      return t ? t.value : def;
    },
    dieTitle() {
      const def = 'This is how many people die in traffic accidents each year';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'dieTitle');
      return t ? t.value : def;
    },
    totalFatalitiesText() {
      const def = 'Total fatalities';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'totalFatalitiesText');
      return t ? t.value : def;
    },
    afterSelfDriving() {
      const def = 'After self-driving cars';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'afterSelfDriving');
      return t ? t.value : def;
    },
    solutionText() {
      const def = 'SOLUTION';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'solutionText');
      return t ? t.value : def;
    },
    solution2Description() {
      const def = 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'solution2Description');
      return t ? t.value : def;
    },
    understandingTest() {
      const def = 'Understanding Test';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'understandingTest');
      return t ? t.value : def;
    },
    pleaseReadAndAnswer() {
      const def = 'Please read and answer the following questions carefully.';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'pleaseReadAndAnswer');
      return t ? t.value : def;
    },
    solution2Question1() {
      const def = `What is the new percentage of pedestrian deaths,
          compared to what it was before self-driving cars?`;
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'solution2Question1');
      return t ? t.value : def;
    },
    theSameAsBefore() {
      const def = 'The same as before';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'theSameAsBefore');
      return t ? t.value : def;
    },
    lessThanBefore() {
      const def = 'Less than before';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'lessThanBefore');
      return t ? t.value : def;
    },
    moreThanBefore() {
      const def = 'More than before';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'moreThanBefore');
      return t ? t.value : def;
    },
    solution2Question2() {
      const def = `Was the number of fatalities reduced for all four categories
        of the road users after self-driving cars are introduced?`;
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'solution2Question2');
      return t ? t.value : def;
    },
    unclear() {
      const def = 'Unclear';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'unclear');
      return t ? t.value : def;
    },
    noText() {
      const def = 'No';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'noText');
      return t ? t.value : def;
    },
    yesText() {
      const def = 'Yes';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'yesText');
      return t ? t.value : def;
    },
    solutionNumber() {
      const pageNumber = 2;
      let index = 1;
      const currentOptions = this.$store.state.staticSurveyOptions;
      for (let i = 0; i < 3; i += 1) {
        if (pageNumber === currentOptions[i]) {
          index = i + 1;
        }
      }
      return index;
    },
    solution2Question3() {
      const def = 'Who uses the road the least?';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'solution2Question3');
      return t ? t.value : def;
    },
    carPassengersText() {
      const def = 'Car passengers';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'carPassengersText');
      return t ? t.value : def;
    },
    pedestrianText() {
      const def = 'Pedestrians';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'pedestrianText');
      return t ? t.value : def;
    },
    motorcyclistsText() {
      const def = 'Motorcyclists';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'motorcyclistsText');
      return t ? t.value : def;
    },
    yourOpinionText() {
      const def = 'Your Opinion';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'yourOpinionText');
      return t ? t.value : def;
    },
    noteRightWrong() {
      const def = `Note that there are no right or wrong answers to these
        questions and what we are looking for are your honest opinions.`;
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'noteRightWrong');
      return t ? t.value : def;
    },
    howFairText() {
      const def = `How fair do you think this solution
        will be to all the users of the road?`;
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'howFairText');
      return t ? t.value : def;
    },
    unfairLabelText() {
      const def = '1 — COMPLETELY UNFAIR';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'unfairLabelText');
      return t ? t.value : def;
    },
    fairLabelText() {
      const def = '9 — COMPLETELY FAIR';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'fairLabelText');
      return t ? t.value : def;
    },
    cyclistsText() {
      const def = 'Cyclists';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'cyclistsText');
      return t ? t.value : def;
    },
    twoWheelersText() {
      const def = 'Motorcyclists';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'twoWheelersText');
      return t ? t.value : def;
    },
    continueText() {
      const def = 'Continue';
      if (!this.translations) {
        return def;
      }
      const t = this.translations.find((r) => r.key === 'continue');
      return t ? t.value : def;
    },
    canContinue() {
      if (this.newNumberOfPedestrians && this.numberOfFatalitiesReduced && this.usesTheRoadTheLeast
        && this.carPassengersScore && this.pedestriansScore
        && this.cyclistsScore && this.twoWheelersScore) {
        return true;
      }
      return false;
    },
    countryData() {
      return this.$store.state.currentCountry;
    },
    twoWheelerRatio() {
      if (!this.countryData && !this.countryData.ratios) {
        return 0.033;
      }
      return this.countryData.ratios.twoWheelerRatio;
    },
    cyclistsRatio() {
      if (!this.countryData && !this.countryData.ratios) {
        return 0.034;
      }
      return this.countryData.ratios.cyclistsRatio;
    },
    pedestrianRatio() {
      if (!this.countryData && !this.countryData.ratios) {
        return 0.033;
      }
      return this.countryData.ratios.pedestrianRatio;
    },
    carRatio() {
      if (!this.countryData && !this.countryData.ratios) {
        return 0.9;
      }
      return this.countryData.ratios.carPassengersRatio;
    },
    appConfigData() {
      return this.$store.state.appConfigData;
    },
    fatalityReductionProportion() {
      if (!this.appConfigData) {
        return 0.5;
      }
      return this.appConfigData.fatalityReductionProportion;
    },
    carPassengerProportion() {
      if (!this.appConfigData) {
        return 0.9;
      }
      return this.appConfigData.carPassengerProportion;
    },
    othersProportion() {
      return (1 - this.carPassengerProportion) / 3;
    },
    afterData() {
      if (!this.countryData) {
        return null;
      }
      return {
        twoWheelers: Math.round(this.countryData.twoWheelerFatalities
          - ((this.countryData.twoWheelerHours / this.totalHours)
          * (this.totalFatalities * this.fatalityReductionProportion))),
        cyclists: Math.round(this.countryData.cyclistsFatalities
          - ((this.countryData.cyclistsHours / this.totalHours)
          * (this.totalFatalities * this.fatalityReductionProportion))),
        pedestrians: Math.round(this.countryData.pedestrianFatalities
          - ((this.countryData.pedestrianHours / this.totalHours)
          * (this.totalFatalities * this.fatalityReductionProportion))),
        carPassengers: Math.round(this.countryData.carPassengerFatalities
          - ((this.countryData.carPassengersHours / this.totalHours)
          * (this.totalFatalities * this.fatalityReductionProportion))),
        saved: Math.round((this.countryData.twoWheelerFatalities
          + this.countryData.cyclistsFatalities
          + this.countryData.pedestrianFatalities
          + this.countryData.carPassengerFatalities) * this.fatalityReductionProportion),
      };
    },
    savedData() {
      if (!this.countryData && !this.afterData) {
        return null;
      }
      return {
        twoWheelers: this.countryData.twoWheelerFatalities - this.afterData.twoWheelers,
        cyclists: this.countryData.cyclistsFatalities - this.afterData.cyclists,
        pedestrians: this.countryData.pedestrianFatalities - this.afterData.pedestrians,
        carPassengers: this.countryData.carPassengerFatalities - this.afterData.carPassengers,
      };
    },
  },
  methods: {
    async submit() {
      await this.$store.dispatch('submitSecondPage', {
        userSessionId: this.$store.state.userSessionInfo.userSessionId,
        pageNumber: 2,
        newPercentageOfPedestrianDeaths: this.newNumberOfPedestrians,
        fatalitiesReduced: this.numberOfFatalitiesReduced,
        leastRoadUsage: this.usesTheRoadTheLeast,
        carPassengersFairness: this.carPassengersScore,
        pedestriansFairness: this.pedestriansScore,
        cyclistFairness: this.cyclistsScore,
        twoWheelersFairness: this.twoWheelersScore,
      });
      const optionsArray = this.$store.state.staticSurveyOptions;
      const pageNumber = 2;
      let index = 0;
      for (let i = 0; i < 3; i += 1) {
        // console.log(optionsArray[i], pageNumber);
        if (pageNumber === optionsArray[i]) {
          index = i;
        }
      }
      // console.log(optionsArray, index);
      sessionStorage.setItem('staticPageCount', index);
      if (index >= 2) {
        this.$store.dispatch('resetStaticSurveyIndex');
        this.$router.push('/solution');
      } else {
        index += 1;
        this.$router.push(`/page${optionsArray[index]}`);
      }
    },
    scrollToTop() {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    },
  },
};
</script>

<style scoped>
.before-self-driving-container{
  margin-bottom: 20px;
}
.after-self-driving-container > h2,
.before-self-driving-container > h2 {
  font-family: 'Lora', serif;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 46px;
  color: #080D25;
}
.after-self-driving-container > h2 {
  margin-bottom: 40px;
}
.before-self-driving-container h4 {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #080D25;
}
.before-self-driving-img, .after-self-driving-img{
  padding: 0 40px;
}
.radio-input{
  margin-left: 14px;
}
.col-value{
  text-align: center;
}
.col-label{
  width: 164px !important;
  min-width: 164px !important;
  max-width: 164px !important;
}
.col-min-value{
  padding-right: 40px;
  min-width: 132px !important;
}
.col-max-value{
  padding-left: 40px;
  min-width: 132px !important;
}
.btn-right{
  float:right;
}
.hours-chart, .fatal-chart{
  margin: 44px 0px;
}
.page-container{
  margin-top: 84px;
}
@media only screen and (max-width: 700px) {
  .page-container{
    margin-top: 34px;
  }
}
.after-self-driving-container{
  margin-bottom: 50px;
  margin-top: 80px;
}
.solution-label{
  background: #080D25;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 16px;
  font-weight: 700;
  width: 124px;
  height: 27px;
  font-family: 'Lato', sans-serif;
}
.solution-label-bottom{
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  margin-top: 8px;
  /* identical to box height */
  color: #080D25;
  font-family: 'Lato', sans-serif;
}
.understanding{
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 46px;
  font-family: 'Lora', serif;
  /* identical to box height */
  color: #080D25;
}
.please-read{
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  color: #57576B;
}

.please-read{
  margin-bottom: 40px;
}

.li-label{
  margin-bottom: 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  font-family: 'Lato', sans-serif;
  color: #080D25;
}

.ol-hr{
  margin: 36px 0px;
}

.radio-container > div {
  margin: 10px 0px;
}

.fairness{
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height */
  color: #080D25;
  margin-bottom: 24px;
}

.unfair{
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  color: #C11313;
}

.fair{
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  color: #079D16;
  text-align: end;
}

.fainrness-label{
  margin-bottom: 20px;
}

.score-label{
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #080D25;
}

.score-container{
  display: flex;
  flex-wrap: wrap;
  margin-top: 18px;
  padding-left: 18px;
}

.continue-container{
  display:flex;
  justify-content: center;
  align-content: center;
}

.icon{
  width: 22px;
  height: 22px;
  margin-right: 6px;
}
</style>
