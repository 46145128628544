<template>
  <div class="container mt-5">
    <h2 class="text-center">Empty survey data</h2>
    <div class="form-group">
      <label for="exampleInputPassword1">Admin key</label>
      <input type="password" class="form-control" id="exampleInputPassword1" placeholder="Admin Key"
        v-model="adminKey">
    </div>
    <button @click="emptyData" class="btn btn-primary m-4">Empty survey data</button>
    <div v-if="errorMessage" class="text-danger">
      {{errorMessage}}
    </div>
    <div v-if="success" class="text-success">
      Done
    </div>
  </div>
</template>

<script>
import adminApi from '../../api/admiApiService';

export default {
  data() {
    return {
      countryCode: null,
      translationKey: null,
      translationValue: null,
      adminKey: '',
      errorMessage: '',
      success: false,
    };
  },
  methods: {
    async emptyData() {
      const response = await adminApi.emptyData({
        adminKey: this.adminKey,
      });
      if (response.status === 200) {
        this.$router.push({
          name: 'Landing',
        });
      } else {
        this.errorMessage = response.data;
      }
    },
    async getStatic() {
      const response = await adminApi.getStaticData({
        adminKey: this.adminKey,
      });
      if (response.status === 200) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'StaticSurveys.csv');
        document.body.appendChild(link);
        link.click();
      } else {
        this.errorMessage = response.data;
      }
    },
  },
};
</script>

<style>

</style>
