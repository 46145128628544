<template>
  <div :class="{ 'scale-down': shouldScaleDown }">
    <div class="survey" id="container" :style="roadImage">
      <div class="cyclist" :style="cyclistImage"></div>
      <div class="truck" :style="TruckImage"></div>
      <div class="car3" :style="Car3Image"></div>
      <div class="car2" :style="Car2Image"></div>
      <div class="redCar" :style="RedCarImage"></div>
      <div class="car" id="item" :style="carImage"></div>
      <div class="left-arrow" :style="leftArrow"
        v-if="state !== 'hitTruck'"
        @click="moveRightAction"
        @mousedown="moveRight"
        @mouseup="clearIntervals"
        @mouseleave="clearIntervals"></div>
      <div class="right-arrow" :style="rightArrow"
        v-if="state !== 'hitBike'"
        @click="moveLeftAction"
        @mousedown="moveLeft"
        @mouseup="clearIntervals"
        @mouseleave="clearIntervals"></div>
      <div class="crash1" :style="crash1"
        v-if="state === 'hitBike'"></div>
      <div class="crash2" :style="crash2"
        v-if="state === 'hitTruck'"></div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    this.shouldScaleDown = window.devicePixelRatio > 1;
  },
  data() {
    return {
      state: 'driving',
      currentScalePosition: 0,
      active: false,
      currentY: null,
      initialY: null,
      xOffSet: 0,
      score: 0,
      moveLeftInterval: null,
      moveRightInterval: null,
      shouldScaleDown: false,
    };
  },
  computed: {
    roadImage() {
      return {
        // eslint-disable-next-line global-require
        backgroundImage: `url(${require('@/assets/Road2.png')})`,
      };
    },
    Car3Image() {
      if (this.state === 'hitTruck' || this.state === 'hitBike') {
        return {
          // eslint-disable-next-line global-require
          backgroundImage: `url(${require('@/assets/Car3.png')})`,
          top: '-150px',
          clipPath: 'polygon(0 65%, 100% 65%, 100% 100%, 0 100%)',
        };
      }
      return {
        // eslint-disable-next-line global-require
        backgroundImage: `url(${require('@/assets/Car3.png')})`,
      };
    },
    Car2Image() {
      if (this.state === 'hitTruck' || this.state === 'hitBike') {
        return {
          // eslint-disable-next-line global-require
          backgroundImage: `url(${require('@/assets/Car2.png')})`,
          top: '350px',
          clipPath: 'none',
        };
      }
      return {
        // eslint-disable-next-line global-require
        backgroundImage: `url(${require('@/assets/Car2.png')})`,
      };
    },
    cyclistImage() {
      return {
        // eslint-disable-next-line global-require
        backgroundImage: `url(${require('@/assets/Motorcycle.png')})`,
      };
    },
    RedCarImage() {
      if (this.state === 'hitTruck') {
        return {
          // eslint-disable-next-line global-require
          backgroundImage: `url(${require('@/assets/SportCar2.png')})`,
          transform: 'rotate(-20deg) translateX(-60px) translateY(-140px)',
        };
      }
      if (this.state === 'hitBike') {
        return {
          // eslint-disable-next-line global-require
          backgroundImage: `url(${require('@/assets/SportCar2.png')})`,
          transform: 'rotate(-30deg) translateX(-25px) translateY(-110px)',
        };
      }
      return {
        // eslint-disable-next-line global-require
        backgroundImage: `url(${require('@/assets/SportCar2.png')})`,
        transform: 'rotate(-20deg)',
      };
    },
    TruckImage() {
      if (this.state === 'hitTruck') {
        return {
          // eslint-disable-next-line global-require
          visibility: 'hidden',
        };
      }
      if (this.state === 'hitBike') {
        return {
          // eslint-disable-next-line global-require
          visibility: 'hidden',
        };
      }
      return {
        // eslint-disable-next-line global-require
        backgroundImage: `url(${require('@/assets/Car4.png')})`,
      };
    },
    carImage() {
      return {
        // eslint-disable-next-line global-require
        backgroundImage: `url(${require('@/assets/SelfDriving_car.png')})`,
      };
    },
    leftArrow() {
      return {
        // eslint-disable-next-line global-require
        backgroundImage: `url(${require('@/assets/ArrowDown.png')})`,
      };
    },
    rightArrow() {
      return {
        // eslint-disable-next-line global-require
        backgroundImage: `url(${require('@/assets/ArrowUp.png')})`,
      };
    },
    crash1() {
      return {
        // eslint-disable-next-line global-require
        backgroundImage: `url(${require('@/assets/Crash1.png')})`,
      };
    },
    crash2() {
      return {
        // eslint-disable-next-line global-require
        backgroundImage: `url(${require('@/assets/Crash2e.png')})`,
      };
    },
  },
  watch: {
    score(val) {
      this.$emit('score-change', val);
    },
  },
  methods: {
    clearIntervals() {
      if (this.moveLeftInterval) {
        clearInterval(this.moveLeftInterval);
        this.moveLeftInterval = null;
      }
      if (this.moveRightInterval) {
        clearInterval(this.moveRightInterval);
        this.moveRightInterval = null;
      }
    },
    moveLeftAction() {
      this.$store.state.stepsCount -= 1;
      const dragItem = document.querySelector('#item');
      this.currentY -= 70 / 30;
      if (this.currentY < 0) {
        this.currentY = this.currentY < -70 ? -70 : this.currentY;
      } else if (this.currentY > 0) {
        this.currentY = this.currentY > 70 ? 70 : this.currentY;
      }
      this.xOffSet = this.currentY;
      this.setTranslate(this.currentY, dragItem);
    },
    moveLeft() {
      this.moveLeftInterval = setInterval(() => {
        this.moveLeftAction();
      }, 200);
    },
    moveRightAction() {
      this.$store.state.stepsCount += 1;
      const dragItem = document.querySelector('#item');
      this.currentY += 70 / 30;
      if (this.currentY < 0) {
        this.currentY = this.currentY < -70 ? -70 : this.currentY;
      } else if (this.currentY > 0) {
        this.currentY = this.currentY > 70 ? 70 : this.currentY;
      }
      this.xOffSet = this.currentY;
      this.setTranslate(this.currentY, dragItem);
    },
    moveRight() {
      this.moveRightInterval = setInterval(() => {
        this.moveRightAction();
      }, 200);
    },
    dragStart(e) {
      const dragItem = document.querySelector('#item');
      this.initialY = e.clientY - this.xOffSet;

      if (e.target === dragItem) {
        this.active = true;
      }
    },
    dragEnd() {
      this.initialY = this.currentY;
      this.active = false;
    },
    drag(e) {
      const dragItem = document.querySelector('#item');
      if (this.active) {
        e.preventDefault();
        this.currentY = e.clientY - this.initialY;
        if (this.currentY < 0) {
          this.currentY = this.currentY < -70 ? -70 : this.currentY;
        } else if (this.currentY > 0) {
          this.currentY = this.currentY > 70 ? 70 : this.currentY;
        }
        if (this.currentY === -70) {
          this.$store.state.stepsCount = -30;
        } else if (this.currentY === 70) {
          this.$store.state.stepsCount = 30;
        } else {
          this.$store.state.stepsCount = parseInt(this.currentY / (70 / 30), 10);
        }
        this.xOffSet = this.currentY;
        this.setTranslate(this.currentY, dragItem);
      }
    },
    setTranslate(xPos, el) {
      if (Math.round(xPos) === -70) {
        this.state = 'hitBike';
        this.clearIntervals();
        this.score = -100;
        // eslint-disable-next-line no-param-reassign
        el.style.transform = `translateY(${xPos}px)`;
      } else if (Math.round(xPos) === 70) {
        this.state = 'hitTruck';
        this.clearIntervals();
        this.score = 100;
        // eslint-disable-next-line no-param-reassign
        el.style.transform = `translateY(${xPos}px)`;
      } else {
        // eslint-disable-next-line no-param-reassign
        el.style.transform = `translateY(${xPos}px)`;
        this.state = 'driving';
        if (xPos === 0) {
          this.score = 0;
        } else if (xPos < 0) {
          this.score = Math.round((xPos / 70) * 100);
        } else {
          this.score = Math.round((xPos / 70) * 100);
        }
      }
    },
  },
  mounted() {
    const container = document.querySelector('#container');

    container.removeEventListener('mousedown', this.dragStart);
    container.addEventListener('mousedown', this.dragStart, false);

    container.removeEventListener('mouseup', this.dragEnd);
    container.addEventListener('mouseup', this.dragEnd, false);

    container.removeEventListener('mousemove', this.drag);
    container.addEventListener('mousemove', this.drag, false);
  },
};
</script>

<style scoped>
.survey{
  width: 1000px;
  height: 600px;
  margin: 0 auto;
  margin-top: 30px;
  background-size: contain;
  position: relative;
}
.cyclist{
  position: absolute;
  width: 90px;
  height: 140px;
  background-size: contain;
  background-repeat: no-repeat;
  left: 470px;
  top: 500px;
  clip-path: polygon(0 0, 100% 0, 100% 71%, 0 71%);
}
.truck{
  position: absolute;
  width: 140px;
  height: 230px;
  background-size: contain;
  background-repeat: no-repeat;
  left: 435px;
  top: -150px;
  clip-path: polygon(0 65%, 100% 65%, 100% 100%, 0 100%);
}

.car3{
  position: absolute;
  width: 140px;
  height: 230px;
  background-size: contain;
  background-repeat: no-repeat;
  left: 710px;
  top: -110px;
  clip-path: polygon(0 48%, 100% 48%, 100% 100%, 0 100%);
}

.car2{
  position: absolute;
  width: 140px;
  height: 230px;
  background-size: contain;
  background-repeat: no-repeat;
  left: 710px;
  top: 470px;
  clip-path: polygon(0 0, 100% 0, 100% 56%, 0 56%);
}

.car{
  position: absolute;
  width: 380px;
  height: 380px;
  background-size: contain;
  background-repeat: no-repeat;
  left: 310px;
  top: 130px;
}

.redCar{
  position: absolute;
  width: 140px;
  height: 230px;
  background-size: contain;
  background-repeat: no-repeat;
  left: 630px;
  top: 170px;
}

.left-arrow{
  position: absolute;
  width: 60px;
  height: 60px;
  background-size: contain;
  background-repeat: no-repeat;
  left: 470px;
  top: 440px;
}

.right-arrow{
  position: absolute;
  width: 60px;
  height: 60px;
  background-size: contain;
  background-repeat: no-repeat;
  left: 470px;
  top: 120px;
}

.crash1{
  position: absolute;
  width: 150px;
  height: 150px;
  background-size: contain;
  background-repeat: no-repeat;
  left: 470px;
  top: 50px;
}
.crash2{
  position: absolute;
  width: 140px;
  height: 140px;
  background-size: 140px;
  background-repeat: no-repeat;
  left: 430px;
  top: 450px;
}

.scale-down{
  transform: scale(0.8);
  margin-top: -40px;
  margin-bottom: -40px;
}
</style>
